import React from "react";
import { referralData, couponData } from "../../../data/referralData";
import * as XLSX from "xlsx";
import Title from "../../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";

const ReferralCoupons = () => {
  const navigate = useNavigate();

  const handleExport = () => {
    const wsReferrals = XLSX.utils.json_to_sheet(referralData);
    const wsCoupons = XLSX.utils.json_to_sheet(couponData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, wsReferrals, "Referrals");
    XLSX.utils.book_append_sheet(wb, wsCoupons, "Coupons");
    XLSX.writeFile(wb, "ReferralCouponsData.xlsx");
  };

  return (
    <div className="">
      <div className="flex justify-between mb-4">
        <Title title="Referrals" goBack={() => navigate(-1)} />
        <div className="flex  items-center gap-6">
          <p className="font-nunito font-normal text-base">
            Active Referral Code Of User:
            <span className="text-green font-bold"> AG45D</span>
          </p>
          <div>
            <button
              onClick={handleExport}
              className="bg-white  text-[#344054] px-4 py-2 rounded-lg "
            >
              Export
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-nunito text-[#202224] font-bold mb-4">
            Referrals Status
          </h2>
          {referralData.map((referral, index) => (
            <div key={index} className=" ">
              {/* <div className="flex justify-between"> */}
              <p className="">
                <strong className="font-manrope text-base font-normal">
                  Referral ID:
                </strong>
                {referral.id}
              </p>
              {/* </div> */}
              <div className="border py-3 px-4 border-green  rounded-xl mb-4 shadow-sm bg-white">
                <div className="flex justify-between mb-2">
                  <p>
                    <strong className="text-[#7B7B7B] text-sm font-normal">
                      Date:
                    </strong>
                  </p>
                  {referral.date}
                </div>

                <div className="flex justify-between mb-2">
                  <p>
                    <strong className="text-[#7B7B7B] text-sm font-normal">
                      Refer Through:
                    </strong>
                  </p>
                  {referral.referredThrough}
                </div>
                <div className="flex justify-between mb-2">
                  <p>
                    <strong className="text-[#7B7B7B] text-sm font-normal">
                      Coupon Earned:
                    </strong>
                  </p>
                  <span className=" flex  items-center font-medium text-[#08875D] text-lg font-manrope">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="22"
                        viewBox="0 0 21 22"
                        fill="none"
                        className="mr-2"
                      >
                        <path
                          d="M13.125 2.65057C12.2964 2.39031 11.4145 2.25 10.5 2.25C5.66751 2.25 1.75 6.16751 1.75 11C1.75 15.8324 5.66751 19.75 10.5 19.75C15.3324 19.75 19.25 15.8324 19.25 11C19.25 10.0855 19.1097 9.20366 18.8494 8.375"
                          stroke="#08875D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M7.4375 8.8125L10.5 11.875L18.3752 3.125"
                          stroke="#08875D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <p>{referral.couponStatus}</p>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <h2 className="text-xl font-nunito text-[#202224] font-bold mb-10">
            Coupons Status
          </h2>
          {couponData.map((coupon, index) => (
            <div
              key={index}
              className={`border border-${coupon.color} p-4 rounded-lg mb-9 shadow-sm ${coupon.color}`}
            >
              <p>
                <strong className="font-sm text-black font-manrope font-medium">
                  {coupon.title}
                </strong>
              </p>
              <p className="font-sm font-manrope text-black font-medium">
                {coupon.description}
              </p>
              <p>
                <strong className="font-sm text-[#7B7B7B] font-manrope font-normal">
                  {coupon.code}
                </strong>
              </p>
              <div className="flex justify-between">
                <p>{/* <strong>Status:</strong> */}</p>
                <p>
                  <span className="font-manrope font-base font-medium">
                    {coupon.status}
                  </span>
                  <span className="text-[#7B7B7B] font-base font-normal ml-2">
                    on {coupon.date}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReferralCoupons;
