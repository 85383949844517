const assessments = [
  {
    title: "Cooking Assessment",
    questionsAttempted: "02/10",
    testScore: "50%",
    badge: "Beginner",
  },
  {
    title: "Cleaning Assessment",
    status: "Not Attempted",
  },
  {
    title: "Babysitting Assessment",
    status: "Not Attempted",
  },
  {
    title: "Pet Care Assessment",
    status: "Not Attempted",
  },
  {
    title: "Patient Care Assessment",
    status: "Not Attempted",
  },
  {
    title: "Vessel Cleaning Assessment",
    status: "Not Attempted",
  },
  {
    title: "Laundry Service Assessment",
    status: "Not Attempted",
  },
];

export default assessments;
