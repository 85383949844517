import React, { useState } from "react";
import Title from "../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";
import taxData from "../../data/finance/taxData";
import ButtonGroup from "../../Buttons/ButtonGroup";

const TaxManagementPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentData, setCurrentData] = useState(taxData);
  const [newValues, setNewValues] = useState({
    serviceCharges: "",
    platformFee: "",
    gst: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewValues({
      ...newValues,
      [name]: value,
    });
  };

  // Handler for saving new values
  const handleSave = () => {
    setCurrentData({
      ...currentData,
      ...Object.fromEntries(
        Object.entries(newValues).map(([key, value]) => [
          key,
          value !== "" ? parseFloat(value) : currentData[key],
        ])
      ),
    });
    setNewValues({
      serviceCharges: "",
      platformFee: "",
      gst: "",
    });
  };

  return (
    <div>
      <Title title="Tax Management" goBack={() => navigate(-1)} />
      <div className="bg-white p-8 rounded-2xl mb-8">
        <h2 className="text-[#606060] text-base font-normal mb-3">
          Current Data
        </h2>
        <div className="flex gap-6 mb-3">
          <div
            className="p-4 rounded border border-green w-36"
            style={{ background: "rgba(181, 228, 202, 0.30)" }}
          >
            <label className="text-sm font-normal font-nunito">
              Service Charges:
            </label>
          </div>
          =
          <div className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4 w-80">
            <span>{currentData.serviceCharges} %</span>
          </div>
        </div>
        <div className="flex gap-6 mb-3">
          <div
            className="p-4 rounded border border-green w-36"
            style={{ background: "rgba(181, 228, 202, 0.30)" }}
          >
            <label className="text-sm font-normal font-nunito">
              Platform Fee:
            </label>
          </div>
          =
          <div className="bg-[#F5F6FA] border border-[#D5D5D5] p-4 rounded w-80">
            <span>{currentData.platformFee} %</span>
          </div>
        </div>
        <div className="flex gap-6 mb-3">
          <div
            className="p-4 rounded border border-green w-36"
            style={{ background: "rgba(181, 228, 202, 0.30)" }}
          >
            <label className="text-sm font-normal font-nunito">GST:</label>
          </div>
          =
          <div className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4 w-80">
            <span>{currentData.gst} %</span>
          </div>
        </div>
        <h2 className="text-[#606060] text-base font-normal mt-16 mb-3">
          Save New Value
        </h2>
        <div className="flex gap-6 mb-3">
          <div
            className="p-4 rounded border border-green w-36"
            style={{ background: "rgba(181, 228, 202, 0.30)" }}
          >
            <label className="text-sm font-normal font-nunito">
              Service Charges
            </label>
          </div>
          =
          <input
            type="text"
            name="serviceCharges"
            value={newValues.serviceCharges}
            onChange={handleInputChange}
            className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4 w-80"
            placeholder="Enter Here"
          />
        </div>
        <div className="flex gap-6 mb-3">
          <div
            className="p-4 rounded border border-green w-36"
            style={{ background: "rgba(181, 228, 202, 0.30)" }}
          >
            <label className="text-sm font-normal font-nunito">
              Platform Fee
            </label>
          </div>
          =
          <input
            type="text"
            name="platformFee"
            value={newValues.platformFee}
            onChange={handleInputChange}
            className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4 w-80"
            placeholder="Enter Here"
          />
        </div>
        <div className="flex gap-6 mb-3">
          <div
            className="p-4 rounded border border-green w-36"
            style={{ background: "rgba(181, 228, 202, 0.30)" }}
          >
            <label className="text-sm font-normal font-nunito">GST:</label>
          </div>
          =
          <input
            type="text"
            name="gst"
            value={newValues.gst}
            onChange={handleInputChange}
            className="bg-[#F5F6FA] border border-[#D5D5D5] rounded p-4 w-80"
            placeholder="Enter Here"
          />
        </div>
        <div className="mt-16 mb-64">
          <ButtonGroup buttons={[{ label: "Save", variant: "save" }]} />
        </div>
      </div>
    </div>
  );
};

export default TaxManagementPage;
