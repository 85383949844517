// src/data/totalData.js
import ConsumersIcon from "../../assets/Icon.png";
import SellersIcon from "../../assets/Icon.png";
import ProductsIcon from "../../assets/Icon.png";
import OrdersIcon from "../../assets/Icon.png";

export const totalServicesData = [
  {
    title: "Total Service Categories ",
    icon: ConsumersIcon,
    count: "8 ",
  },
  {
    title: "Total Service Sub-Categories ",
    icon: SellersIcon,
    count: "34",
  },
];
