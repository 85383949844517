import React from "react";
import ReferralCoupons from "./ReferralCoupons";

const RefferalPage = () => {
  return (
    <div>
      <ReferralCoupons />
    </div>
  );
};

export default RefferalPage;
