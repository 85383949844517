import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { totalConsumerData } from "../../data/totalConsumerStat";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import userData from "../../data/consumerTable";
import GenericTable from "../../GenericTable/GenericTable";

function ConsumerPage() {
  const navigate = useNavigate();

  const columns = [
    { header: "User ID", key: "id" },
    { header: "User Name", key: "name" },
    { header: "Gender", key: "gender" },
    { header: "Mobile No.", key: "phone" },
    { header: "Status", key: "status" },
  ];

  const handleAddConsumerClick = () => {
    navigate("/add-consumer");
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-3xl font-bold mb-4 font-nunito text-[#202224]">
          Consumer (User) Management
        </h2>
        <button
          onClick={handleAddConsumerClick}
          className="rounded-[8px] bg-green md:mb-6 md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
        >
          + Add New Consumer
        </button>
      </div>
      <TotalStat data={totalConsumerData} />
      <GenericTable
        title=""
        columns={columns}
        data={userData}
        redirectTo="/review"
        showFilters={true}
      />
    </div>
  );
}

export default ConsumerPage;
