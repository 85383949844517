import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadButton from "../../Buttons/UploadButton";
import Title from "../../Title/Title";

function AddNewServiceProvider() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    dateOfJoining: "",
    mobileNo: "",
    profilePic: null,
    document: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
  };
  const handleUpload = () => {
    navigate("/upload-bank-detail");
  };
  const handleService = () => {
    navigate("/add-service");
  };
  return (
    <div className="mx-auto">
      <Title title="Add New Service Provider" goBack={() => navigate(-1)} />
      <form
        onSubmit={handleSubmit}
        className="space-y-4 text-[#606060]  md:text-sm md:mb-8 font-semibold font-nunito bg-white p-8 rounded-2xl "
      >
        <div className="grid grid-cols-3 gap-8">
          {/* Form Section */}
          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">User ID</label>
                <input
                  type="text"
                  name="id"
                  value={formData.id}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Date of Joining</label>
                <input
                  type="date"
                  name="dateOfJoining"
                  value={formData.dateOfJoining}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Personal Details
            </h2>

            {/* Full Name and Gender Fields */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Gender</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Mobile no.</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Email</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Date of Birth</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Marital Status</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Language Proficiency</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Education</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>
            <div>
              <label className="block mb-1">About (Bio)</label>
              <input
                type="text"
                name="gender"
                value={formData.bio}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded"
              />
            </div>

            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Verification Details
            </h2>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Aadhaar Number</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>

              <div>
                <label className="block mb-1">PAN Number</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mb-4">
              <div>
                <label
                  htmlFor="electricity-bill"
                  className="text-sm font-semibold text-[#606060] font-nunito "
                >
                  Electricity Bill
                </label>

                <input
                  type="file"
                  id="electricity-bill"
                  className="hidden  "
                  onChange={(e) => console.log("Selected")}
                />
                <label
                  htmlFor="electricity-bill"
                  className="block bg-[#F5F6FA] w-full text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
                >
                  Upload
                </label>
              </div>
            </div>
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Address Details
            </h2>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Title</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>

              <div>
                <label className="block mb-1">House No./Flat No.</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Address line 1</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>

              <div>
                <label className="block mb-1">Address line 2</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Pin Code</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Landmark</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:mt-16">
              <ButtonGroup
                buttons={[
                  {
                    label: "Discard",
                    variant: "discard",
                  },
                  { label: "Delete", variant: "delete" },
                  { label: "Save", variant: "save" },
                ]}
              />
            </div>
          </div>
          {/* Profile Picture Section */}
          <div className="col-span-1 flex flex-col items-center">
            <div className="mb-4 relative">
              <div className="w-32 h-32 rounded-full border-2 border-gray-300 bg-gray-300 flex items-center justify-center overflow-hidden">
                {formData.profilePic ? (
                  <img
                    alt="Profile"
                    className="w-full h-full object-cover"
                    src={URL.createObjectURL(formData.profilePic)}
                  />
                ) : (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      className="w-12 h-12"
                    >
                      <path
                        d="M12 35.949C12.2574 38.5658 12.8391 40.3272 14.1538 41.6418C16.5119 44 20.3072 44 27.8978 44C35.4884 44 39.2838 44 41.6418 41.6418C44 39.2838 44 35.4884 44 27.8978C44 20.3072 44 16.5119 41.6418 14.1538C40.3272 12.8391 38.5658 12.2574 35.949 12"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <path
                        d="M4 20C4 12.4575 4 8.6863 6.34314 6.34314C8.6863 4 12.4575 4 20 4C27.5424 4 31.3138 4 33.6568 6.34314C36 8.6863 36 12.4575 36 20C36 27.5424 36 31.3138 33.6568 33.6568C31.3138 36 27.5424 36 20 36C12.4575 36 8.6863 36 6.34314 33.6568C4 31.3138 4 27.5424 4 20Z"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <path
                        d="M4 22.2371C5.23804 22.0797 6.48968 22.0021 7.74342 22.0047C13.0473 21.9067 18.2213 23.3527 22.3422 26.0849C26.164 28.6189 28.8494 32.1061 30 36.0001"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.9995 14H26.0175"
                        stroke="black"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </div>
            <button
              type="button"
              className="w-1/2 text-black p-2 rounded border border-black mb-8"
            >
              Add Profile Pic.
            </button>

            <UploadButton label="Bank Details" handleUpload={handleUpload} />
            <UploadButton
              label="Service Details"
              handleUpload={handleService}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewServiceProvider;
