const userData = [
  {
    id: 1,
    name: "John Doe",
    category: "Consumer",
    gender: "Male",
    status: "verified",
  },
  {
    id: 2,
    name: "Jane Smith",
    category: "Consumer",
    gender: "Female",
    status: "pending",
  },
  {
    id: 3,
    name: "Mike Johnson",
    category: "Service Provider",
    gender: "Male",
    status: "rejected",
  },
  {
    id: 4,
    name: "Alice Brown",
    category: "Service Provider",
    gender: "Female",
    status: "verified",
  },
];

export default userData;
