import React from "react";
import Title from "../../Title/Title"; // Keeps your original Title component
import { useLocation, useNavigate } from "react-router-dom";
import assessments from "../../data/serviceprovider/Assessments"; // Import the assessment data

const AssessmentCard = ({
  title,
  questionsAttempted,
  testScore,
  badge,
  status,
}) => {
  return (
    <div className=" mb-4">
      <h3 className="font-bold font-nunito text-xl">{title}</h3>
      {questionsAttempted && (
        <div className="mt-4 grid grid-cols-3 gap-4">
          {/* First Row: Questions Attempted */}
          <div>
            <label className="block font-semibold text-base text-[#606060]">
              Questions Attempted
            </label>
            <input
              type="text"
              value={questionsAttempted}
              readOnly
              className="mt-1 block w-full border rounded-md px-2 py-2"
            />
          </div>

          {/* First Row: Test Score */}
          <div>
            <label className="block font-semibold text-base text-[#606060]">
              Test Score
            </label>
            <input
              type="text"
              value={testScore}
              readOnly
              className="mt-1 block w-full border rounded-md px-2 py-2"
            />
          </div>

          {/* Second Row: Badge */}
          <div className="col-span-2">
            <label className="block font-semibold text-base text-[#606060]">
              Badge Won
            </label>
            <input
              type="text"
              value={badge}
              readOnly
              className="mt-1 block w-full border rounded-md px-2 py-2 "
            />
          </div>
        </div>
      )}
      {status && (
        <div className="text-red-500 mt-2 w-2/3 border border-[#D5D5D5] rounded-md bg-[#F5F6FA] py-2 px-4">
          <p className="text-sm font-normal">{status}</p>
        </div>
      )}
    </div>
  );
};

const Assessments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  return (
    <div>
      {/* Title Component */}
      <Title
        title={`${consumerData.username || "User"} - Assessments`}
        goBack={() => navigate(-1)} // Ensures "goBack" functionality works
      />

      {/* Assessments Section */}
      <div className=" bg-white rounded-2xl md:mb-8 p-6">
        <div className="space-y-4">
          {assessments.map((assessment, index) => (
            <AssessmentCard key={index} {...assessment} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Assessments;
