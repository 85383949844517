import React from "react";
import { useNavigate } from "react-router-dom";
import GenericTC from "../GenericTc";

const AboutUs = () => {
  const navigate = useNavigate();

  const aboutData = {
    title: "About Us",
    termsAndConditions:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  };

  const handleSave = (data) => {
    console.log("Booking-specific save logic", data);
  };

  return (
    <div>
      <GenericTC
        initialTitle={aboutData.title}
        initialTerms={aboutData.termsAndConditions}
        onSave={handleSave}
        goBack={() => navigate(-1)}
        label="About Us Body Content"
      />
    </div>
  );
};

export default AboutUs;
