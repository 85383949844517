import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import Title from "../../Title/Title";

const AddBankDetail = () => {
  const navigate = useNavigate();
  const additionalFields = [
    { label: "Account Number", name: "acc" },
    { label: "IFSC Code", name: "ifsc" },
  ];
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center self-start ">
        <Title title="Add Bank Details" goBack={() => navigate(-1)} />
      </div>

      <div className="w-4/5 space-y-6 bg-white p-8 rounded-2xl mb-8">
        <h2 className="text-xl font-bold text-black font-nunito  mb-3">
          Linked Bank Details
        </h2>

        <div className=" mb-8">
          <div className="grid grid-cols-2  gap-x-10 gap-y-3 mb-3">
            {additionalFields.map((field) => (
              <div key={field.name}>
                <label className="text-sm font-semibold text-[#606060] font-nunito">
                  {field.label}
                </label>
                <input
                  type="text"
                  name={field.name}
                  value={field.value}
                  placeholder="Enter here"
                  className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
                />
              </div>
            ))}
          </div>

          <div className="flex flex-col items-start space-y-2 md:mb-16">
            <label
              htmlFor="acc"
              className="text-sm  font-semibold text-[#606060] font-nunito"
            >
              Re-Enter Account Number
            </label>
            <input
              type="text"
              id="acc"
              placeholder="Enter Here"
              className="block w-full value:text-green text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="flex gap-6 font-lato md:text-sm font-normal capitalize  ">
            <ButtonGroup buttons={[{ label: "Save", variant: "save" }]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBankDetail;
