// data/faq.js
const faqData = [
  {
    id: 1,
    question: "What is Lorem Ipsum?",
    answer: "Lorem Ipsum is dummy text used in printing.",
  },
  {
    id: 2,
    question: "Why do we use it?",
    answer: "It helps with design and layout testing.",
  },
];

export default faqData;
