import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadButton from "../../Buttons/UploadButton";
import Title from "../../Title/Title";

function ConsumerDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  const [formData, setFormData] = useState({
    id: consumerData?.id || "",
    name: consumerData?.name || "",
    dateOfJoining: consumerData?.doj || "",
    mobileNo: consumerData?.phone || "",
    email: consumerData?.email || "",
    aadhaar: consumerData?.adhar || "",
    pan: consumerData?.pan || "",
    gender: consumerData?.gender || "",
    address: consumerData?.address || {},
  });

  const formFields = [
    { label: "User ID", name: "id", value: formData.id },
    {
      label: "Date of Joining",
      name: "dateOfJoining",
      value: formData.dateOfJoining,
    },
    { label: "Full Name", name: "name", value: formData.name },
    { label: "Gender", name: "gender", value: formData.gender },
    { label: "Mobile No.", name: "mobileNo", value: formData.mobileNo },
    { label: "Email", name: "email", value: formData.email },
    { label: "Aadhaar Number", name: "aadhaar", value: formData.aadhaar },
    { label: "PAN Number", name: "pan", value: formData.pan },
  ];

  const addressFields = [
    { label: "Title", name: "title", value: formData.address?.title },
    {
      label: "House No./Flat No.",
      name: "house",
      value: formData.address?.houseNumber,
    },
    {
      label: "Address Line 1",
      name: "add1",
      value: formData.address?.addressLine1,
    },
    {
      label: "Address Line 2",
      name: "add2",
      value: formData.address?.addressLine2,
    },
    { label: "Pin Code", name: "pin", value: formData.address?.pinCode },
    { label: "Landmark", name: "land", value: formData.address?.landmark },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
  };

  const handleUpload = () => {
    navigate("/upload-doc?mode=view", {
      state: {
        username: consumerData.name,
        status: consumerData.status,
      },
    });
  };

  const handleUploadWallet = () => {
    navigate("/view-wallet", {
      state: {
        username: consumerData.name,
        ...consumerData,
        status: consumerData.status,
      },
    });
  };

  const handleBooking = () => {
    navigate("/booking", {
      state: {
        username: consumerData.name,
        status: consumerData.status,
      },
    });
  };

  const handleReferrals = () => {
    navigate("/refer", {
      state: {
        username: consumerData.name,
        status: consumerData.status,
      },
    });
  };

  useEffect(() => {
    if (!consumerData) {
      navigate("/consumer");
    }
  }, [consumerData, navigate]);

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center self-center">
        <Title
          title={`${consumerData.name || "User"} `}
          goBack={() => navigate(-1)}
        />

        <div className="flex gap-6 items-center">
          <button className="text-[#344054] border text-sm font-lato font-normal h-10 px-5 rounded-lg border-[#D0D5DD] flex items-center justify-center">
            Export
          </button>

          <span
            className={` ${consumerData?.statusColor} text-white font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center`}
          >
            {consumerData?.status}
          </span>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="space-y-4 text-[#606060] md:text-sm md:mb-8 font-semibold font-nunito bg-white p-8 rounded-2xl md:mt-6"
      >
        <div className="grid grid-cols-3 gap-8">
          {/* Form Section */}
          <div className="col-span-2">
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Personal Details
            </h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {formFields.map((field) => (
                <div key={field.name}>
                  <label className="block mb-1">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={handleChange}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              ))}
            </div>

            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Address Details
            </h2>

            <div className="grid grid-cols-2 gap-4 mb-4">
              {addressFields.map((field) => (
                <div key={field.name}>
                  <label className="block mb-1">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={handleChange}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              ))}
            </div>

            <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:mt-16">
              <ButtonGroup
                buttons={[
                  { label: "Delete", variant: "discard" },
                  {
                    label: "Reject",
                    variant: "delete",
                    disabled:
                      consumerData?.status === "verified" ||
                      consumerData?.status === "rejected",
                  },
                  {
                    label:
                      consumerData?.status === "verified"
                        ? "Verified"
                        : "Verify",
                    variant: "save",
                  },
                ]}
              />
            </div>
          </div>

          {/* Profile Picture Section */}
          <div className="col-span-1 flex flex-col items-center">
            <div className="mb-4 relative">
              <div className="w-32 h-32 rounded-full border-2 border-gray-300 bg-gray-300 flex items-center justify-center overflow-hidden">
                {consumerData.pic ? (
                  <img
                    alt="Profile"
                    className="w-full h-full object-cover"
                    src={consumerData.pic}
                  />
                ) : (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      className="w-12 h-12"
                    >
                      <path
                        d="M12 35.949C12.2574 38.5658 12.8391 40.3272 14.1538 41.6418C16.5119 44 20.3072 44 27.8978 44C35.4884 44 39.2838 44 41.6418 41.6418C44 39.2838 44 35.4884 44 27.8978C44 20.3072 44 16.5119 41.6418 14.1538C40.3272 12.8391 38.5658 12.2574 35.949 12"
                        stroke="black"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <UploadButton label="Documents" handleUpload={handleUpload} />
              <UploadButton label="Wallet" handleUpload={handleUploadWallet} />
              <UploadButton label="Bookings" handleUpload={handleBooking} />
              <UploadButton label="Referrals" handleUpload={handleReferrals} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ConsumerDetails;
