import React from "react";
import arrow from "../../assets/arrow.png";

const UploadButton = ({ label, handleUpload, badge }) => {
  return (
    <div
      style={{ boxShadow: "6px 6px 54px 0px rgba(0, 0, 0, 0.05)" }}
      onClick={handleUpload}
      className="p-4 md:mt-0 border rounded-2xl flex md:mb-6 justify-between bg-white"
    >
      <div className="flex flex-col items-start">
        <button
          onClick={handleUpload}
          className="text-black font-nunito font-bold text-lg"
        >
          {label}
        </button>
        {badge && (
          <div
            style={{ background: `rgba(8, 135, 93, 0.2) ` }}
            className="mt-4 text-sm  font-lato font-normal  text-black  py-[6px] px-[12px] rounded-lg inline-block"
          >
            {badge}
          </div>
        )}
      </div>
      <img src={arrow} className="md:w-8 md:h-8" alt="arrow icon" />
    </div>
  );
};

export default UploadButton;
