import React from "react";
import transactions from "../../../data/transaction";
const Transaction = () => {
  return (
    <div>
      <h2 className="font-nunito font-bold text-xl">Transaction Details</h2>

      <div className="md:mt-4 md:pb-40  w-10/12">
        {transactions.map((transaction, index) => (
          <div key={index} className="">
            {/* Transaction Header */}
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-[#606060] font-semibold font-nunito">
                Transaction ID #{transaction.id}
              </span>
              <span className="text-sm text-[#606060] font-semibold">
                {transaction.date}
              </span>
            </div>

            {/* Transaction Body */}
            <div className="flex justify-between items-center border border-gray-300 rounded-lg p-4 mb-4 bg-[#f5f6f8]">
              <span className="text-sm font-normal text-black">
                {transaction.description}
              </span>
              <span
                className={`text-base font-lato font-bold ${
                  transaction.type === "credit" ? "text-green" : "text-red-600"
                }`}
              >
                ₹{transaction.amount.toLocaleString()}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Transaction;
