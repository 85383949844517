import React from "react";
import CurrentBalance from "./CurrentBalance";
import Transaction from "./Transaction";

const ConsumerWallet = () => {
  return (
    <div>
      <CurrentBalance />
    </div>
  );
};

export default ConsumerWallet;
