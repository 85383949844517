import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../../Title/Title";

const ViewReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { consumerData } = location.state || {};

  return (
    <div>
      <Title
        title={`${consumerData?.consumername || "User"} - Reviews`}
        goBack={() => navigate(-1)}
      />
      <div className="border bg-white rounded-2xl h-screen p-8 md:mt-6 md:mb-8">
        <div>
          <p className="text-xl font-bold font-nunito">Reviews Given</p>
          <p className="text-sm font-nunito font-semibold text-[#606060] md:mt-3">
            12 January, 2024
          </p>
        </div>
        <div className="flex justify-between rounded-md items-center md:mt-3 border border-t bg-[#F5F6FA] p-4">
          <p className="font-nunito font-normal text-sm">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <div className="flex items-center space-x-1">
            {/* Filled stars */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-[#FF9900]"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-[#ff9900]"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
            {/* Empty stars */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-300"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-300"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-300"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewReview;
