// src/data/totalData.js
import ConsumersIcon from "../../assets/Icon.png";
import SellersIcon from "../../assets/Icon.png";
import ProductsIcon from "../../assets/Icon.png";
import OrdersIcon from "../../assets/Icon.png";

export const totalConsumerData = [
  {
    title: "Total Consumers",
    icon: ConsumersIcon,
    count: "40,689",
  },
  {
    title: "New Consumers",
    icon: SellersIcon,
    count: "12,345",
  },
  {
    title: "Verified Consumers",
    icon: ProductsIcon,
    count: "8,976",
  },
  {
    title: "Pending Consumers",
    icon: OrdersIcon,
    count: "25,482",
  },
];
