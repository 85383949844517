// Popup.js
import React from "react";

const SuccessPopup = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center w-1/3">
        <div className="flex justify-center mb-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M6 26.6666C6 26.6666 9 28 13 34C13 34 13.5697 33.0384 14.6427 31.5052M34 12C29.417 14.2915 24.6238 19.1036 20.7758 23.6446"
              stroke="#08875D"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 26.6666C16 26.6666 19 28 23 34C23 34 34 17 44 12"
              stroke="#08875D"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <h2 className="text-xl font-semibold mb-6 font-lato">
          Congratulations!
        </h2>
        <p className="text-md mb-8 text-[#7B7B7B] font-lato text-xl">
          {message}
        </p>
        <button
          onClick={onClose}
          className="bg-green w-1/3 rounded-lg text-lg font-bold font-lato text-white px-4 py-2 "
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;
