import React from "react";
import Title from "../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";
import userData from "../../data/booking";
import GenericTable from "../../GenericTable/GenericTable";

const Refund = () => {
  const navigate = useNavigate();
  const columns = [
    { header: "Consumer Name", key: "consumername" },
    { header: "Booked Service Name", key: "name" },
    { header: "Refund Amount", key: "refundAmount" },
    { header: "Status", key: "refundStatus" },
  ];

  const handleRedirect = (rowData) => {
    navigate("/refund-detail", { state: { username: rowData.consumername } });
  };

  return (
    <div>
      <div>
        <Title title="Refund Management" goBack={() => navigate(-1)} />
      </div>

      <div>
        <GenericTable
          title=""
          columns={columns}
          data={userData}
          showFilters={true}
          reviewText="View Details"
          redirectTo="/refund-detail"
        />
      </div>
    </div>
  );
};

export default Refund;
