import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { totalServiceProviderData } from "../../data/totalServiceStat";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import userData from "../../data/serviceProvide";
import GenericTable from "../../GenericTable/GenericTable";
import Title from "../../Title/Title";

function ServiceProviderPage() {
  const navigate = useNavigate(); // Initialize the navigate function

  const columns = [
    { header: "User ID", key: "id" },
    { header: "User Name", key: "name" },
    { header: "Gender", key: "gender" },
    { header: "Mobile No.", key: "phone" },
    { header: "Status", key: "status" },
  ];

  const handleAddConsumerClick = () => {
    navigate("/add-service-provider");
  };

  // const handleReviewClick = (rowData) => {
  //   navigate("/review", {
  //     state: { consumer: rowData },
  //   });
  // };
  return (
    <div>
      <div className="flex justify-between">
        <Title
          title=" Service Provider (Maid) Management"
          goBack={() => navigate(-1)}
        />
        <button
          onClick={handleAddConsumerClick}
          className="rounded-[8px] bg-green md:mb-6 md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
        >
          + Add New Service Provider
        </button>
      </div>
      <TotalStat data={totalServiceProviderData} />
      <GenericTable
        title=""
        columns={columns}
        data={userData}
        redirectTo="/review-service-provider"
      />
    </div>
  );
}

export default ServiceProviderPage;
