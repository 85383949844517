import React, { useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadProfilePic from "../../Buttons/UploadProfilePic";
import imgService from "../../../assets/serviceImg.png";
import arrow from "../../../assets/arrow.png";

const ReviewService = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const consumerData = location.state || {};
  const serviceName = consumerData.serviceName || "Service Details";

  const [formData, setFormData] = useState({
    id: consumerData?.id || "",
    name: consumerData?.serviceName || "",
    serviceProviders: consumerData?.serviceProviders || "",

    consumers: consumerData?.consumers || "",
    image: consumerData?.image || "",
  });
  return (
    <div>
      <div>
        <Title title={serviceName} goBack={() => navigate(-1)} />
      </div>
      <div>
        <form className="space-y-4 text-[#606060] md:mb-8 md:text-sm font-semibold font-nunito bg-white py-8 px-5 rounded-2xl">
          <div className="grid grid-cols-3 gap-8">
            {/* Form Section */}
            <div className="col-span-2">
              <div className="grid grid-cols-1 gap-4 mb-4">
                <div>
                  <label className="block mb-1">Service Category Name</label>
                  <input
                    type="text"
                    name="category"
                    value={consumerData.serviceName}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 mb-6">
                <div>
                  <label className="block mb-1">Sub-Categories</label>
                  <input
                    type="text"
                    name="sub"
                    // value={consumerData.subCategoryDetails}
                    value="Vegetarain"
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 mb-6">
                <div>
                  <label className="block mb-1">Sub-Categories</label>
                  <input
                    type="text"
                    name="sub"
                    // value={consumerData.subCategoryDetails}
                    value="Non Vegetarain"
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 mb-6">
                <div className="border border-dashed w-2/4 rounded-lg border-green p-3">
                  <p className="text-center text-green font-lato text-sm font-normal">
                    + Add New Sub-Category
                  </p>
                </div>
              </div>

              <div
                className="grid grid-cols-1 gap-4 mb-4"
                onClick={() => navigate(`/detail-page/${serviceName}`)}
              >
                <div className="relative">
                  <label className="block mb-1">
                    Total Service Providers offering cooking services
                  </label>
                  <input
                    type="text"
                    name="totalrate"
                    defaultValue="4"
                    className="w-full border border-gray-300 p-2 rounded"
                    readOnly
                  />
                  <img
                    src={arrow}
                    alt="Arrow Icon"
                    className="absolute right-3 w-5 h-5 top-3/4 transform -translate-y-1/2"
                  />
                </div>
              </div>

              <div
                className="grid grid-cols-1 gap-4 mb-4"
                onClick={() => navigate(`/detail-service-page/${serviceName}`)}
              >
                <div className="relative">
                  <label className="block mb-1">
                    Total Service Providers offering cooking services
                  </label>
                  <input
                    type="text"
                    name="totalrate"
                    // value={consumerData.subCategory}
                    defaultValue="4"
                    className="w-full border border-gray-300 p-2 rounded"
                  />

                  <img
                    src={arrow}
                    alt="Arrow Icon"
                    className="absolute right-3 w-5 h-5 top-3/4 transform -translate-y-1/2"
                  />
                </div>
              </div>

              {/* 
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Address Details
            </h2> */}

              <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:mt-16">
                <ButtonGroup
                  buttons={[
                    {
                      label: "Discard",
                      variant: "discard",
                    },
                    { label: "Delete", variant: "delete" },
                    { label: "Save", variant: "save" },
                  ]}
                />
              </div>
            </div>
            {/* Profile Picture Section */}

            <div className="col-span-1 flex flex-col items-center">
              <img src={imgService} />
              {/* <UploadProfilePic buttonText="Change Picture" /> */}
              {/* <p className="text-xs text-green font-lato font-normal">
                Note - Image should not be larger than 1MB (250*450)
              </p> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviewService;
