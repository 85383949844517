import React from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../GenericTable/GenericTable";
import referralTable from "../../data/booking";

const ServiceProviderPaymentPage = () => {
  const navigate = useNavigate();
  const columns = [
    { header: "Service Provider Name", key: "serviceProvider" },
    { header: "Booked Service Name", key: "name" },
    { header: "Salary Amount", key: "SalaryAmount" },
    { header: " Status", key: "paymentStatus" },
  ];
  return (
    <div>
      <div>
        <Title
          title="Service Provider (Maid) Payments "
          goBack={() => navigate(-1)}
        />
      </div>

      <div>
        <GenericTable
          title=""
          columns={columns}
          data={referralTable}
          redirectTo="/view-provider-payment"
          showFilters={true}
          reviewText="View Details"
        />
      </div>
    </div>
  );
};

export default ServiceProviderPaymentPage;
