import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";

const AddNewCoupon = () => {
  const navigate = useNavigate();

  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    couponDesc: "",
    couponCode: "",
    refundamt: "",
    serviceprovider: "",
    expDate: "",
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission (to be implemented as needed)
  const handleSubmit = () => {
    console.log("Form data submitted:", formData);
    // Add logic to save the coupon data
  };

  // Define form fields dynamically
  const formFields = [
    {
      name: "name",
      label: "Coupon Name",
      placeholder: "Enter Coupon Name",
    },
    {
      name: "couponDesc",
      label: "Coupon Description",
      placeholder: "Enter Coupon Description",
    },
    {
      name: "couponCode",
      label: "Coupon Code",
      placeholder: "Enter Coupon Code",
    },
    {
      name: "refundamt",
      label: "Refund Amount",
      placeholder: "Enter Refund Amount",
    },
    {
      name: "serviceprovider",
      label: "Service Provider",
      placeholder: "Enter Service Provider",
    },
    {
      name: "expDate",
      label: "Expires On (Valid till)",
      placeholder: "Enter Expiration Date",
    },
  ];

  return (
    <div>
      <div>
        <Title title="Add New Coupon" goBack={() => navigate(-1)} />
      </div>
      <div>
        <div className="bg-white p-8 rounded-2xl mb-6">
          <form
            className="grid grid-cols-1 gap-6 w-3/4"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {formFields.map((field) => (
              <div key={field.name} className="grid grid-cols-1">
                <label className="block mb-1 text-[#606060]">
                  {field.label}
                </label>
                <input
                  type="text"
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  placeholder={field.placeholder}
                  className="w-full border text-black border-gray-300 p-2 rounded"
                />
              </div>
            ))}
            <div className="mt-14">
              <ButtonGroup
                buttons={[
                  {
                    label: "Discard",
                    variant: "discard",
                  },
                  {
                    label: "Save",
                    variant: "save",
                    onClick: handleSubmit,
                  },
                ]}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewCoupon;
