import React from "react";
import Title from "../../Title/Title";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrow from "../../../assets/arrow.png";
import ButtonGroup from "../../Buttons/ButtonGroup";
import serviceProviderData from "../../data/serviceProvide";

const ViewRefundDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  const formFields = [
    { label: "Service Name", name: "servicename", value: consumerData.name },
    {
      label: "Service Provider",
      name: "serviceprovider",
      value: consumerData.serviceProvider,
    },
    { label: "Time Slot", name: "timeslot", value: consumerData.timeSlot },
    { label: "Date", name: "date", value: consumerData.date },
    { label: "Address", name: "address", value: consumerData.address },
  ];

  const additionalFields = [
    { label: "Booking ID", name: "id", value: consumerData.id },
    {
      label: "Payment Method",
      name: "method",
      value: consumerData.paymentMethod,
    },
    { label: "Placed On", name: "placed", value: consumerData.orderplaced },
    { label: "Paid Via", name: "paidvia", value: consumerData.paidVia },
    { label: "Coupon Applied", name: "coupon", value: consumerData.coupon },
    { label: "Tips Given", name: "tip", value: consumerData.tip },
  ];

  return (
    <div>
      <div className="flex justify-between">
        <Title
          title={`Refund Management - ${
            consumerData.consumername || "Unknown User"
          }`}
          goBack={() => navigate(-1)}
        />

        {/* <span
          className={` ${consumerData?.statusColor}  font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center`}
        >
          {consumerData?.refundStatus}
        </span> */}
      </div>

      <div>
        <form className="space-y-4 text-[#606060] bg-white p-8 rounded-2xl md:mt-6 md:mb-8">
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-2">
              {formFields.map((field) => (
                <div key={field.name} className="grid grid-cols-1 mb-3">
                  <label className="block mb-1">{field.label}</label>
                  {field.name === "serviceprovider" ? (
                    <div className="flex items-center justify-between border text-black border-gray-300 p-2 rounded bg-gray-100">
                      <span className="flex-grow">{field.value}</span>
                      <Link
                        to="/review-service-provider"
                        state={{ providerId: consumerData.serviceProviderId }}
                        className="text-green hover:underline text-sm ml-3"
                      >
                        View Details
                      </Link>
                    </div>
                  ) : (
                    <input
                      type="text"
                      name={field.name}
                      value={field.value}
                      readOnly
                      className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
                    />
                  )}
                </div>
              ))}

              <div className="grid grid-cols-1 gap-4 mb-4 text-black">
                <div className="">
                  <label className="block  text-[#606060] text-sm font-semibold mb-3">
                    Receipt
                  </label>
                  <div className="grid grid-cols-1 border border-dashed p-3 border-green bg-[#f5f6fa] rounded-md">
                    <div className="flex justify-between font-sm font-manrope p-2">
                      <h1 className="font-normal">Monthly Price</h1>
                      <h1 className="font-semibold ">₹30000</h1>
                    </div>
                    <div className="flex justify-between font-sm font-manrope p-2">
                      <h1 className="font-normal">Service Charges</h1>
                      <h1 className="font-semibold ">₹30</h1>
                    </div>
                    <div className="flex justify-between font-sm font-manrope p-2">
                      <h1 className="font-normal">Platform Fee</h1>
                      <h1 className="font-semibold ">₹90</h1>
                    </div>
                    <div className="flex justify-between font-sm font-manrope p-2">
                      <h1 className="font-normal">GST</h1>
                      <h1 className="font-semibold ">₹30</h1>
                    </div>
                    <hr className="h-[0.1px] border-none bg-[#08875D]" />

                    <div className="flex justify-between font-sm font-manrope p-2">
                      <h1 className=" font-bold">Grand Total</h1>
                      <h1 className="font-semibold text-green ">₹30000</h1>
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-sm  font-semibold text-[#606060] font-nunito mt-6 mb-3">
                Other Description
              </p>
              <div className="grid grid-cols-1 gap-4 mb-4 text-black">
                <div className="">
                  <label className="block mb-1"></label>
                  <div className="flex justify-between items-center border border-[#D5D5D5] bg-[#f5f6fa] rounded-md">
                    <p className="text-black text-sm font-normal font-nunito leading-5 p-4">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-10 gap-y-3 mb-3">
                {additionalFields.map((field) => (
                  <div key={field.name}>
                    <label className="block mb-1">{field.label}</label>
                    <input
                      type="text"
                      name={field.name}
                      value={field.value}
                      readOnly
                      className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
                    />
                  </div>
                ))}
              </div>

              {/* Attendance & Leaves Section - Link added */}
              <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
                Attendance & Leaves
              </h2>
              <div className="grid grid-cols-1 gap-4 mb-4 text-black">
                <div className="relative">
                  <label className="block mb-1"></label>
                  <Link to="/attendance-leaves" state={{ consumerData }}>
                    <div className="flex justify-between items-center">
                      <div className="w-full border bg-[#F5F6FA] placeholder:text-black text-black p-3 pr-10 rounded">
                        Attendance and Leave Details
                      </div>
                      <img
                        src={arrow}
                        alt="Arrow Icon"
                        className="absolute right-3 w-4 h-4 top-1/2 transform -translate-y-1/2"
                      />
                    </div>
                  </Link>
                </div>
              </div>

              {/* Reviews Section - Link added */}
              <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
                Reviews
              </h2>
              <div className="grid grid-cols-1 gap-4 mb-4 text-black">
                <div className="relative">
                  <label className="block mb-1"></label>
                  <Link to="/customer-reviews" state={{ consumerData }}>
                    <div className="flex justify-between items-center">
                      <div className="w-full border bg-[#F5F6FA] placeholder:text-black text-black p-3 pr-10 rounded">
                        Review of booked service provider
                      </div>
                      <img
                        src={arrow}
                        alt="Arrow Icon"
                        className="absolute right-3 w-4 h-4 top-1/2 transform -translate-y-1/2"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="flex items-end justify-end">
                <span
                  className={` ${consumerData?.statusColor}  font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center `}
                >
                  {consumerData?.refundStatus}
                </span>
              </div>
            </div>

            <ButtonGroup
              buttons={[
                {
                  label:
                    consumerData?.refundStatus === "Pending"
                      ? "Make Refund ₹1000"
                      : consumerData?.refundStatus === "Completed"
                      ? "Refund Completed"
                      : "",
                  variant: "save",
                  disabled: consumerData?.refundStatus === "Completed",
                  onClick: () => {
                    if (consumerData?.refundStatus === "Pending") {
                      navigate("/makerefund", {
                        state: {
                          consumername: consumerData.consumername,
                          serviceProvider: consumerData.serviceProvider,
                        },
                      });
                    }
                  },
                },
              ]}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewRefundDetail;
