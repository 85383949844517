import React from "react";
import Title from "../../Title/Title";
import { Navigate, useNavigate } from "react-router-dom";
import TotalStat from "../../Dashboard/MainDashboard/TotalStat/TotalStat";
import { totalRefferal } from "../../data/finance/referalStat";
import GenericTable from "../../GenericTable/GenericTable";
import referralTable from "../../data/finance/refferalTable";

const ReferralsPage = () => {
  const navigate = useNavigate();
  const columns = [
    { header: "User ID", key: "id" },
    { header: "User Name", key: "name" },
    { header: "Referral Date", key: "referralDate" },
    { header: "Coupon Status", key: "status" },
  ];

  const handleClick = () => {
    navigate("/referral-managemnt");
  };
  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Title title="Referrals" goBack={() => navigate(-1)} />
        </div>
        <button
          onClick={handleClick}
          className="rounded-[8px] bg-green md:mb-6 md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
        >
          Referral Management
        </button>
      </div>
      <div>
        <TotalStat data={totalRefferal} />
        <GenericTable
          title=""
          columns={columns}
          data={referralTable}
          redirectTo="/refer"
          showFilters={true}
        />
      </div>
    </div>
  );
};

export default ReferralsPage;
