import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import UploadButton from "../../Buttons/UploadButton";

const ContentManagementPage = () => {
  const navigate = useNavigate();
  const handleHomeBanner = () => {
    navigate("/home-banner");
  };

  const handleFaq = () => {
    navigate("/faq-manager");
  };
  const handleReplaceServiceProvider = () => {
    navigate("/replace-service-provider");
  };
  const handleBookingTc = () => {
    navigate("/booking-tc");
  };
  const handleServiceGuide = () => {
    navigate("/service-guide");
  };
  const handleAntiDiscriminatory = () => {
    navigate("/anti-desc");
  };
  const handlePrivacy = () => {
    navigate("/privacy");
  };

  const handleTermCondition = () => {
    navigate("/term-condition");
  };
  const handleAbout = () => {
    navigate("/about");
  };
  return (
    <div>
      <div className="flex flex-row gap-8">
        <Title title="Content Management" goBack={() => navigate(-1)} />

        <div className="rounded-lg bg-black text-white font-lato item-center ">
          <button className="px-10 text-sm py-[10px]  font-bold text-white rounded-lg">
            CONSUMER
          </button>
        </div>

        <div className="rounded-lg bg-white border border-black text-white font-lato item-center ">
          <button className="px-10 text-sm py-[10px] font-bold  text-black rounded-lg">
            SERVICE PROVIDER
          </button>
        </div>
      </div>
      <div className="container mt-12">
        <div className="grid grid-cols-2 gap-9">
          <UploadButton
            label="Homescreen Banner"
            badge="Total 3 Banners"
            handleUpload={handleHomeBanner}
          />
          <UploadButton
            label="FAQs"
            badge="Total 4 FAQs"
            handleUpload={handleFaq}
          />
        </div>

        <div className="grid grid-cols-2 gap-9">
          <UploadButton
            label="Replace Service Provider Reasons"
            badge="Reasons & Terms and Conditions"
            handleUpload={handleReplaceServiceProvider}
          />
          <UploadButton
            label="Booking Details T&C"
            badge="Terms and Conditions"
            handleUpload={handleBookingTc}
          />
        </div>

        <p className="text-[#202224] text-2xl font-nunito  font-medium mb-3">
          More Options
        </p>

        <div className="grid grid-cols-2 gap-9">
          <UploadButton
            label="Service Guidelines"
            handleUpload={handleServiceGuide}
          />
          <UploadButton
            label="Anti-Discriminatory Policy"
            handleUpload={handleAntiDiscriminatory}
          />
        </div>

        <div className="grid grid-cols-2 gap-9">
          <UploadButton label="Privacy Policy" handleUpload={handlePrivacy} />
          <UploadButton
            label="Terms & Conditions"
            handleUpload={handleTermCondition}
          />
        </div>

        <div className="grid grid-cols-2 gap-9">
          <UploadButton label="About Us" handleUpload={handleAbout} />
        </div>
      </div>
    </div>
  );
};

export default ContentManagementPage;
