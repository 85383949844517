import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../../Title/Title";
import replaceServiceProviderData from "../../../data/content/replaceservice";
import edit from "../../../../assets/edit.png";
import del from "../../../../assets/del.png";
import ButtonGroup from "../../../Buttons/ButtonGroup";
const ReplaceServiceProvider = () => {
  const navigate = useNavigate();

  const [reasons, setReasons] = useState(replaceServiceProviderData);
  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({ reason: "" });
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);

  const handleEdit = (id) => {
    const reasonToEdit = reasons.find((reason) => reason.id === id);
    setFormData(reasonToEdit);
    setEditingId(id);
  };

  const handleSave = (id) => {
    setReasons((prev) =>
      prev.map((reason) =>
        reason.id === id ? { ...reason, ...formData } : reason
      )
    );
    setEditingId(null);
    setFormData({ reason: "" });
  };

  const handleDelete = (id) => {
    setReasons((prev) => prev.filter((reason) => reason.id !== id));
  };

  const handleAdd = () => {
    setReasons((prev) => [...prev, { id: Date.now(), ...formData }]);
    setFormData({ reason: "" });
    setIsAddFormVisible(false); // Hide form after adding
  };

  const toggleAddForm = () => {
    setIsAddFormVisible((prev) => !prev);
  };

  return (
    <div className="min-h-screen">
      <header className="flex justify-between items-center mb-6">
        <div>
          <Title
            title="Replace Service Provider Reasons"
            goBack={() => navigate(-1)}
          />
        </div>
      </header>

      <div className="p-6 bg-white rounded-2xl">
        {/* Terms and Conditions Section */}
        <div className="mb-6">
          <h2 className="font-semibold text-lg text-gray-700 mb-2">
            Terms and Conditions
          </h2>
          <textarea
            className="border p-4 rounded w-full text-sm font-normal bg-gray-100"
            readOnly
            value="Please review the terms and conditions for replacing service providers."
          />
        </div>

        {/* Reasons Section */}
        {reasons.map((reason, index) => (
          <div
            key={reason.id}
            className="py-4 flex items-center justify-between"
          >
            <div className="flex-1">
              <h3 className="font-semibold text-gray-600 text-sm">
                Reason {index + 1}
              </h3>
              <textarea
                value={
                  editingId === reason.id ? formData.reason : reason.reason
                }
                onChange={(e) => {
                  if (editingId === reason.id) {
                    setFormData({ ...formData, reason: e.target.value });
                  }
                }}
                className={`border p-2 rounded w-full mt-2 text-sm ${
                  editingId === reason.id ? "bg-white" : "bg-gray-100"
                }`}
                readOnly={editingId !== reason.id}
              />
            </div>

            <div className="flex space-x-2 ml-4">
              {editingId === reason.id ? (
                <button
                  onClick={() => handleSave(reason.id)}
                  className="px-4  bg-green-500 rounded text-black"
                >
                  Save
                </button>
              ) : (
                <div onClick={() => handleEdit(reason.id)}>
                  <img src={edit} className="h-5 w-5" alt="edit" />
                </div>
              )}
              <div onClick={() => handleDelete(reason.id)}>
                <img src={del} className="h-5 w-5" alt="Delete" />
              </div>
            </div>
          </div>
        ))}

        <div className="mt-6">
          {isAddFormVisible && (
            <div className="flex flex-col space-y-2 mt-4">
              <textarea
                value={formData.reason}
                onChange={(e) =>
                  setFormData({ ...formData, reason: e.target.value })
                }
                placeholder="New reason"
                className="border p-2 rounded"
              />
              <button
                onClick={handleAdd}
                className="px-4 py-2 bg-green-500 text-white rounded shadow"
              >
                Save Reason
              </button>
            </div>
          )}
          <button
            onClick={toggleAddForm}
            className="px-4 py-2 text-sm w-44 text-green border border-green border-dashed rounded shadow"
          >
            {isAddFormVisible ? "Save" : "+ Add Reason"}
          </button>
        </div>

        <div className="flex gap-6 font-lato md:text-sm font-normal capitalize lg:mt-16">
          <ButtonGroup
            buttons={[
              { label: "Discard", variant: "delete" },
              { label: "Save", variant: "save" },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplaceServiceProvider;
