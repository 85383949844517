import React from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../GenericTable/GenericTable";
import couponTable from "../../data/finance/coupon";

const CouponOfferPage = () => {
  const navigate = useNavigate();

  const columns = [
    { header: "Coupon Name", key: "name" },
    { header: "Coupon Code", key: "couponCode" },
    { header: "Expires On", key: "expDate" },
  ];
  const handleClick = () => {
    navigate("/add-new-coupon");
  };

  return (
    <div>
      <div>
        <div className="flex justify-between">
          <div>
            <Title title="Coupon & Offers" goBack={() => navigate(-1)} />
          </div>
          <div className="flex justify-between gap-6">
            <div>
              <button
                //   onClick={handleExport}
                className="bg-white  text-[#344054] px-4 py-2 rounded-lg "
              >
                Export
              </button>
            </div>

            <div>
              <button
                onClick={handleClick}
                className="rounded-[8px] bg-green md:mb-6 md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
              >
                + Add New Coupon
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <GenericTable
          title=""
          columns={columns}
          data={couponTable}
          redirectTo="/coupon-review"
          showFilters={false}
        />
      </div>
    </div>
  );
};

export default CouponOfferPage;
