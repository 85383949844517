const coupon = [
  {
    name: "Discount Offer 001",
    couponCode: "GET100",
    desc: "Get a flat discount on your purchase.",
    percentoff: "10%",
    expDate: "1 December, 2023",
  },
  {
    name: "Referral Bonus 002",
    couponCode: "GET100",
    desc: "Earn rewards by referring your friends.",
    percentoff: "15%",
    expDate: "28 November, 2023",
  },
  {
    name: "Special Promo 003",
    couponCode: "GET100",
    desc: "Exclusive promo for special customers.",
    percentoff: "20%",
    expDate: "15 November, 2023",
  },
];

export default coupon;
