import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import wallet from "../../../../assets/wallets.png";
import Transaction from "./Transaction";
import NoTransaction from "./NoTransection";
import Title from "../../../Title/Title";

const CurrentBalance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};
  const winningAmount = 10; // Change these values to test
  const currentBalance = 10;

  return (
    <>
      <div>
        <Title
          title={`${consumerData.username || "User"} - Wallet`}
          goBack={() => navigate(-1)}
        />

        <div className="bg-white md:mt-9 rounded-2xl p-8 h-screen-max md:mb-8">
          <div className="w-3/5">
            <div
              className="border-green p-3 rounded-[12px]"
              style={{
                backgroundColor: "rgba(181, 228, 202, 0.25)",
                boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <div className="flex justify-between">
                <div className="gap-2 flex">
                  <img
                    src={wallet}
                    className="md:w-6 md:h-6"
                    alt="Wallet Icon"
                  />
                  <p className="text-black md:text-base font-medium font-manrope">
                    Current Wallet Balance
                  </p>
                </div>
                <div className="font-manrope text-base font-bold">
                  ₹{currentBalance ? currentBalance.toFixed(2) : "0.00"}
                </div>
              </div>

              {/* <div>
                <div className="flex justify-between md:mt-6 bg-white p-2 rounded-[10px]">
                  <p className="font-manrope text-sm font-normal">
                    Total Winning Amount:
                  </p>
                  ₹{winningAmount ? winningAmount.toFixed(2) : "0.00"}
                </div>
              </div> */}
            </div>
          </div>

          {currentBalance === 0 && winningAmount === 0 ? (
            <div className="mt-8 ">
              <NoTransaction />
            </div>
          ) : (
            <div className="md:mt-8">
              <Transaction />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentBalance;
