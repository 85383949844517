import React from "react";

function Navbar() {
  return (
    <div className="bg-white text-[#404040] p-4 pl-[67px] pr-8 fixed w-full top-0 z-10 flex justify-between items-center">
      <h1 className="text-2xl   text-[#08875D] font-extrabold ">MaidEaze</h1>

      <div className="flex items-center space-x-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="26"
          viewBox="0 0 24 26"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0277 0C7.73472 0 5.80843 1.72411 5.55522 4.00306L4.5 13.5H1.5C0.671573 13.5 0 14.1716 0 15V16.5C0 17.3284 0.671573 18 1.5 18H22.5C23.3284 18 24 17.3284 24 16.5V15C24 14.1716 23.3284 13.5 22.5 13.5H19.5L18.4448 4.00306C18.1916 1.72411 16.2653 0 13.9723 0H10.0277Z"
            fill="#08875D"
          />
          <rect x="9" y="19.5" width="6" height="6" rx="2.25" fill="#B5E4CA" />
        </svg>

        {/* Profile Icon */}
        <div className="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center text-white">
          {/* Placeholder for user icon */}
          <span className="text-sm">U</span>
        </div>

        {/* User Name and Role */}
        <div className="text-sm text-left">
          <div className="font-semibold">User Name</div>
          <div className="text-gray-400">Admin</div>
        </div>

        {/* Dropdown Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className="text-gray-400 cursor-pointer"
        >
          <path
            d="M10 10.7929L7.73162 8.14645C7.56425 7.95118 7.29289 7.95118 7.12553 8.14645C6.95816 8.34171 6.95816 8.65829 7.12553 8.85355L9.69695 11.8536C9.86432 12.0488 10.1357 12.0488 10.303 11.8536L12.8745 8.85355C13.0418 8.65829 13.0418 8.34171 12.8745 8.14645C12.7071 7.95118 12.4358 7.95118 12.2684 8.14645L10 10.7929Z"
            fill="#565656"
          />
        </svg>
      </div>
    </div>
  );
}

export default Navbar;
