import React from "react";

const ServiceTable = ({ columns, data, onReviewClick }) => {
  return (
    <div className="overflow-x-auto bg-white py-6 md:mt-9 md:px-7 rounded-xl">
      <table className="min-w-full bg-white rounded-lg">
        <thead className="bg-[#F1F4F9]">
          <tr className="border rounded-3xl border-transparent">
            {columns.map((col) => (
              <th
                key={col.key}
                className="px-4 py-5 border-gray-300 text-[#202224] text-left font-bold font-lato md:text-sm"
              >
                {col.header}
              </th>
            ))}
            <th className="px-4 py-5 border-gray-300 text-left text-gray-700 font-medium">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} className="hover:bg-gray-100 cursor-pointer">
              {columns.map((col) => (
                <td
                  key={col.key}
                  className="px-4 py-5 border-b border-gray-300 font-nunito text-[#202224] font-semibold md:text-sm"
                >
                  {row[col.key]}
                </td>
              ))}
              <td className="px-4 py-5 border-b border-gray-300">
                <button
                  onClick={(event) => onReviewClick(event, row)} // Call the passed function
                  className="text-green font-normal font-lato hover:underline"
                >
                  Review
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceTable;
