const ticketData = [
  {
    SNo: 1,
    ticketid: "14776853",
    username: "Emily Clark",
    phone: "+91-9911887766",
    name: "Login Issue",
    Date: "2024-12-01",
    status: "Resolved",
    email: "Akshaysingh@gmail.com",
  },
  {
    SNo: 2,
    ticketid: "14776853",
    phone: "+91-9911887766",
    email: "Akshaysingh@gmail.com",

    name: "Payment Failed",
    Date: "2024-12-02",
    status: "Pending",
  },
  {
    SNo: 3,
    ticketid: "14776853",
    username: "John Doe",
    phone: "+91-9911887766",
    email: "Akshaysingh@gmail.com",

    name: "Account Suspension",
    Date: "2024-12-03",
    status: "Rejected",
  },
  {
    SNo: 4,
    ticketid: "14776853",
    username: "Akash Singh",
    phone: "+91-9911887766",
    email: "Akshaysingh@gmail.com",

    name: "App Crash",
    Date: "2024-12-04",
    status: "Resolved",
  },
  {
    SNo: 5,
    ticketid: "14776853",
    phone: "+91-9911887766",
    email: "Akshaysingh@gmail.com",

    username: "Akshay Singh",
    name: "Refund Request",
    Date: "2024-12-05",
    status: "Pending",
  },
];

export default ticketData;
