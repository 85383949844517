import React from "react";
import Title from "../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";

const ReferalManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};
  const formFields = [
    {
      label: "Reward Percentage",
      name: "refamt",
      value: "10%",
    },
    {
      label: "Coupon Code",
      name: "cuponcode",
      value: "GET10",
    },
    {
      label: "Coupon Title",
      value: "Referral Reward",
      name: "couponTitle",
    },
    {
      label: "Coupon Description",
      name: "coupondesc",
      value: "Get 10% off on your first transaction.",
    },
    {
      label: "Coupon Validation Time",
      name: "validdate",
      value: "30 Days",
    },
    {
      label: "Referral Limitations for a user",
      name: "reflimitation",
      value: "10 Times",
    },
  ];

  return (
    <div>
      <div>
        <Title title="Referral Management" goBack={() => navigate(-1)} />
      </div>

      <div>
        <div className="bg-white p-8 rounded-2xl mb-6">
          <div className="grid grid-cols-1 gap-6 w-3/4">
            {formFields.map((field) => (
              <div key={field.name} className="grid grid-cols-1 ">
                <label className="block mb-1 text-[#606060]">
                  {field.label}
                </label>
                <input
                  type="text"
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  readOnly={
                    field.name === "refundamt" ||
                    field.name === "serviceprovider"
                  }
                  className={`w-full border text-black border-gray-300 p-2 rounded ${
                    field.name === "refundamt" ||
                    field.name === "serviceprovider"
                      ? "bg-gray-100 cursor-not-allowed"
                      : ""
                  }`}
                />
              </div>
            ))}
          </div>
          <div className="mt-14">
            <ButtonGroup
              buttons={[
                {
                  label: "Delete",
                  variant: "discard",
                },
                {
                  label: "Edit",
                  variant: "delete",
                },
                {
                  label: "Save",
                  variant: "save",
                  //   onClick: handleConfirmClick,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferalManagement;
