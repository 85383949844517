const transactions = [
  {
    id: "123456",
    date: "12 July, 2024",
    description: "Refund Amount",
    amount: 100,
    type: "credit",
  },
  {
    id: "123456",
    date: "01 March, 2024",
    description: "Amount paid towards cleaning service",
    amount: 10000,
    type: "debit",
  },
  {
    id: "123456",
    date: "01 March, 2024",
    description: "Amount paid towards pet care service",
    amount: 3000,
    type: "debit",
  },
  {
    id: "123456",
    date: "01 March, 2024",
    description: "Amount paid towards cooking service",
    amount: 10000,
    type: "debit",
  },
  {
    id: "123456",
    date: "12 July, 2024",
    description: "Refund Amount",
    amount: 100,
    type: "credit",
  },
];

export default transactions;
