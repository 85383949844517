import React from "react";
import PropTypes from "prop-types";

const ButtonGroup = ({ buttons }) => {
  return (
    <div className="flex gap-6 font-lato md:text-sm font-normal capitalize">
      {buttons.map(({ label, onClick, variant, disabled }, index) => (
        <button
          key={index}
          onClick={!disabled ? onClick : undefined}
          disabled={disabled}
          className={`py-2 px-8 rounded-lg border shadow-custom transition-transform ${
            disabled
              ? "cursor-not-allowed opacity-50"
              : "hover:translate-y-[-2px]"
          } ${
            variant === "discard"
              ? "bg-white text-black border-black"
              : variant === "delete"
              ? "bg-black text-white border-black"
              : variant === "save"
              ? "bg-green text-white border-green-500"
              : ""
          }`}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.oneOf(["discard", "delete", "save"]).isRequired,
      disabled: PropTypes.bool, // Optional, determines if the button is disabled
    })
  ).isRequired,
};

export default ButtonGroup;
