import React from "react";
import { useNavigate } from "react-router-dom";
import { totalServicesData } from "../../data/totalServices";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import servicesData from "../../data/services/serviceTableData";
import ServiceTable from "../../GenericTable/ServiceTable";

function ServiceManagement() {
  const navigate = useNavigate();

  const columns = [
    { header: "S.No.", key: "id" },
    { header: "Service Name", key: "serviceName" },
    { header: "Sub-categories", key: "subCategory" },
    { header: "Service Providers", key: "serviceProviders" },
    { header: "Consumers", key: "consumers" },
  ];

  const handleAddConsumerClick = () => {
    navigate("/add-new-service");
  };
  const handleReviewClick = (event, row) => {
    event.stopPropagation();
    navigate(`/service-details/${row.id}`, {
      state: { serviceName: row.serviceName },
    });
  };

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-3xl font-bold mb-4 font-nunito text-[#202224]">
          Services Management
        </h2>
        <button
          onClick={handleAddConsumerClick}
          className="rounded-[8px] bg-green md:mb-6 md:py-3 text-sm font-bold md:px-10 text-white md:leading-5"
        >
          + Add New Service
        </button>
      </div>
      <TotalStat data={totalServicesData} />
      <ServiceTable
        columns={columns}
        data={servicesData}
        onReviewClick={handleReviewClick}
      />
    </div>
  );
}

export default ServiceManagement;
