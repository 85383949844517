import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";
import SuccessPopup from "./SuccessPopup";

const MakeRefund = () => {
  const location = useLocation();
  const consumerData = location.state || {};

  const [isPopupVisible, setPopupVisible] = useState(false);

  const paymentMethodOptions = [
    "Credit Card",
    "Debit Card",
    "Net Banking",
    "UPI",
    "Wallet",
  ];

  const formFields = [
    {
      label: "Total Refund Amount",
      name: "refundamt",
      value: "₹1000",
    },
    {
      label: "Service Provider",
      name: "serviceprovider",
      value: consumerData.serviceProvider,
    },
    { label: "Payment Method", name: "method", value: consumerData.method },
    {
      label: "Card Holder Name",
      name: "holdername",
      placeholder: "Enter card holder name",
    },
    {
      label: "Card Number",
      name: "cardname",
      placeholder: "Enter card number",
    },
    { label: "Expiration Date", name: "expdate", placeholder: "MM/YY" },
    { label: "CVV", name: "cvv", placeholder: "Enter CVV" },
  ];

  const handleConfirmClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <div>
      <Title
        title={`Refund Management - ${
          consumerData.consumername || "Unknown User"
        }`}
        goBack={() => Navigate(-1)}
      />

      <div className="bg-white p-8 rounded-2xl mb-8">
        <div className="grid grid-cols-1 gap-5 w-3/4">
          {formFields.map((field) => (
            <div key={field.name} className="grid grid-cols-1 mb-3">
              <label className="block mb-1">{field.label}</label>
              {field.name === "method" ? (
                <select
                  name={field.name}
                  defaultValue={field.value || ""}
                  className="w-full border text-black border-gray-300 p-2 rounded "
                >
                  <option value="" disabled>
                    Select Payment Method
                  </option>
                  {paymentMethodOptions.map((method, index) => (
                    <option key={index} value={method}>
                      {method}
                    </option>
                  ))}
                </select>
              ) : field.name === "serviceprovider" ? (
                <div className="flex items-center justify-between border text-black border-gray-300 p-2 rounded bg-gray-100">
                  <span>{field.value}</span>
                </div>
              ) : (
                <input
                  type="text"
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  readOnly
                  className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
                />
              )}
            </div>
          ))}
        </div>
        <div className="mt-14">
          <ButtonGroup
            buttons={[
              {
                label: "Discard",
                variant: "discard",
              },
              {
                label: "Confirm",
                variant: "save",
                onClick: handleConfirmClick,
              },
            ]}
          />
        </div>
      </div>

      {isPopupVisible && (
        <SuccessPopup
          message="Refund Successfully done!"
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default MakeRefund;
