import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import arrow from "../../../assets/greenarrow.png";
import Title from "../../Title/Title";

function ViewBookingDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  if (!Object.keys(consumerData).length) {
    navigate("/consumer");
  }

  const formFields = [
    { label: "Service Name", name: "servicename", value: consumerData.name },
    {
      label: "Service Provider",
      name: "serviceprovider",
      value: consumerData.serviceProvider,
    },
    { label: "Time Slot", name: "timeslot", value: consumerData.timeSlot },
    { label: "Date", name: "date", value: consumerData.date },
    { label: "Address", name: "address", value: consumerData.address },
  ];

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center">
        <Title
          title={`${consumerData.name || "User"} `}
          goBack={() => navigate(-1)}
        />
        <div className="flex gap-6 items-center">
          <button className="text-white bg-black border text-sm font-lato font-normal h-10 px-5 rounded-lg border-[#D0D5DD] flex items-center justify-center">
            Download Invoice
          </button>
        </div>
      </div>

      <form className="space-y-4 text-[#606060] bg-white p-8 rounded-2xl md:mt-6 md:mb-8">
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-2">
            {formFields.map((field) => (
              <div key={field.name} className="grid grid-cols-1  mb-3">
                <label className="block mb-1">{field.label}</label>
                <input
                  type="text"
                  name={field.name}
                  value={field.value}
                  readOnly
                  className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
                />
              </div>
            ))}

            <div className="grid grid-cols-1 gap-4 mb-4 text-black">
              <div className="">
                <label className="block  text-[#606060] text-sm font-semibold mb-3">
                  Receipt
                </label>
                <div className="grid grid-cols-1 border border-dashed border-green bg-[#f5f6fa] rounded-md">
                  <div className="flex justify-between font-sm font-manrope p-2">
                    <h1 className=" font-bold">Monthly Salary</h1>
                    <h1 className="font-semibold text-green ">₹30000</h1>
                  </div>
                </div>
              </div>
            </div>

            <p className="text-sm  font-semibold text-[#606060] font-nunito mt-6 mb-3">
              Other Description by Client
            </p>
            <div className="grid grid-cols-1 gap-4 mb-4 text-black">
              <div className="">
                <label className="block mb-1"></label>
                <div className="flex justify-between items-center border border-[#D5D5D5] bg-[#f5f6fa] rounded-md">
                  <p className="text-black text-sm font-normal font-nunito leading-5 p-4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
            </div>

            {/* Attendance & Leaves Section - Link added */}
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Attendance & Leaves
            </h2>
            <div className="grid grid-cols-1 gap-4 mb-4 text-black">
              <div className="relative">
                <label className="block mb-1"></label>
                <Link to="/attendance-leaves" state={{ consumerData }}>
                  <div className="flex justify-between items-center">
                    <div className="w-full border bg-[#F5F6FA] placeholder:text-black text-black p-3 pr-10 rounded">
                      Attendance and Leave Details
                    </div>
                    <img
                      src={arrow}
                      alt="Arrow Icon"
                      className="absolute right-3 w-4 h-4 top-1/2 transform -translate-y-1/2"
                    />
                  </div>
                </Link>
              </div>
            </div>

            {/* Reviews Section - Link added */}
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Reviews
            </h2>
            <div className="grid grid-cols-1 gap-4 mb-4 text-black">
              <div className="relative">
                <label className="block mb-1"></label>
                <Link to="/customer-reviews" state={{ consumerData }}>
                  <div className="flex justify-between items-center">
                    <div className="w-full border bg-[#F5F6FA] placeholder:text-black text-black p-3 pr-10 rounded">
                      Review of booked service provider
                    </div>
                    <img
                      src={arrow}
                      alt="Arrow Icon"
                      className="absolute right-3 w-4 h-4 top-1/2 transform -translate-y-1/2"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="flex items-end justify-end">
              <span
                className={` ${consumerData?.statusColor} text-white font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center `}
              >
                {consumerData?.status}
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ViewBookingDetail;
