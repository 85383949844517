// src/data/totalData.js
import ConsumersIcon from "../../../assets/totalTicket.png";
import SellersIcon from "../../../assets/newTicket.png";
import ProductsIcon from "../../../assets/resolved.png";
import OrdersIcon from "../../../assets/pending.png";

export const totalTickets = [
  {
    title: "Total Tickets",
    icon: ConsumersIcon,
    count: "40,689",
  },
  {
    title: "New Tickets",
    icon: SellersIcon,
    count: "12,345",
  },
  {
    title: "Resolved Tickets",
    icon: ProductsIcon,
    count: "8,976",
  },
  {
    title: "Pending Tickets",
    icon: OrdersIcon,
    count: "25,482",
  },
];
