// src/data/totalData.js
import ConsumersIcon from "../../../assets/Icon.png";
import SellersIcon from "../../../assets/activeCoup.png";
import ProductsIcon from "../../../assets/redeemCoup.png";

export const totalRefferal = [
  {
    title: "Total Number Of Referrals",
    icon: ConsumersIcon,
    count: "40,689",
  },
  {
    title: "Active Coupons",
    icon: SellersIcon,
    count: "12,345",
  },
  {
    title: "Redeemed Coupons",
    icon: ProductsIcon,
    count: "8,976",
  },
];
