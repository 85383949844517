import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadButton from "../../Buttons/UploadButton";
import Title from "../../Title/Title";

function ServiceProviderDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  const [formData, setFormData] = useState({
    id: consumerData?.id || "",
    name: consumerData?.name || "",
    dateOfJoining: consumerData?.doj || "",
    adhar: consumerData?.adhar || "",
    pan: consumerData?.pan || "",
    dob: consumerData?.dob || "",
    marital: consumerData?.marital || "",
    lang: consumerData?.lang || "",
    edu: consumerData?.edu || "",
    mobileNo: consumerData?.phone || "",
    email: consumerData?.email || "",
    aadhaar: consumerData?.adhar || "",
    pan: consumerData?.pan || "",
    gender: consumerData?.gender || "",
    address: consumerData?.address || {},
    addressPermanent: consumerData?.addressPermanent || {},
  });

  const formFields = [
    { label: "User ID", name: "id", value: formData.id },
    {
      label: "Date of Joining",
      name: "dateOfJoining",
      value: formData.dateOfJoining,
    },
    { label: "Full Name", name: "name", value: formData.name },
    { label: "Gender", name: "gender", value: formData.gender },
    { label: "Mobile No.", name: "mobileNo", value: formData.mobileNo },
    { label: "Email", name: "email", value: formData.email },
    { label: "Date of Birth", name: "dob", value: formData.dob },
    { label: "Marital Status", name: "marital", value: formData.marital },
    { label: "Language Proficiency", name: "lang", value: formData.lang },
    { label: "Education", name: "edu", value: formData.edu },
  ];

  const verificationFields = [
    { label: "Aadhaar Number", name: "adhar", value: formData.adhar },
    { label: "PAN", name: "pan", value: formData.pan },
  ];
  const addressFieldsResident = [
    { label: "Title", name: "title", value: formData.address?.title },
    {
      label: "House No./Flat No.",
      name: "house",
      value: formData.address?.houseNumber,
    },
    {
      label: "Address Line 1",
      name: "add1",
      value: formData.address?.addressLine1,
    },
    {
      label: "Address Line 2",
      name: "add2",
      value: formData.address?.addressLine2,
    },
    { label: "Pin Code", name: "pin", value: formData.address?.pinCode },
    { label: "Landmark", name: "land", value: formData.address?.landmark },
  ];
  const addressFieldsPermanent = [
    { label: "Title", name: "title", value: formData.addressPermanent?.title },
    {
      label: "House No./Flat No.",
      name: "house",
      value: formData.addressPermanent?.houseNumber,
    },
    {
      label: "Address Line 1",
      name: "add1",
      value: formData.addressPermanent?.addressLine1,
    },
    {
      label: "Address Line 2",
      name: "add2",
      value: formData.addressPermanent?.addressLine2,
    },
    {
      label: "Pin Code",
      name: "pin",
      value: formData.addressPermanent?.pinCode,
    },
    {
      label: "Landmark",
      name: "land",
      value: formData.addressPermanent?.landmark,
    },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
  };

  const handleAssessments = () => {
    navigate("/assements", {
      state: {
        username: consumerData.name,
        status: consumerData.status,
      },
    });
  };

  const handleBankDetail = () => {
    navigate("/view-bank-detail", {
      state: {
        username: consumerData.name,
        ...consumerData,
        status: consumerData.status,
      },
    });
  };

  const handleServiceDetails = () => {
    navigate("/view-service-details", {
      state: {
        username: consumerData.name,
        status: consumerData.status,
      },
    });
  };

  const handleBookings = () => {
    navigate("/view-bookings", {
      state: {
        username: consumerData.name,
        status: consumerData.status,
      },
    });
  };

  useEffect(() => {
    if (!consumerData) {
      navigate("/consumer");
    }
  }, [consumerData, navigate]);

  return (
    <div className="mx-auto">
      <div className="flex justify-between items-center self-center">
        <Title
          title={`${consumerData.name || "User"} `}
          goBack={() => navigate(-1)}
        />

        <div className="flex gap-6 items-center">
          <button className="text-[#344054] border text-sm font-lato font-normal h-10 px-5 rounded-lg border-[#D0D5DD] flex items-center justify-center">
            Export
          </button>

          <span
            className={` ${consumerData?.statusColor} text-white font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center`}
          >
            {consumerData?.status}
          </span>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="space-y-4 text-[#606060] md:text-sm md:mb-8 font-semibold font-nunito bg-white p-8 rounded-2xl md:mt-6"
      >
        <div className="grid grid-cols-3 gap-8">
          {/* Form Section */}
          <div className="col-span-2">
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Personal Details
            </h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {formFields.map((field) => (
                <div key={field.name}>
                  <label className="block mb-1">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={handleChange}
                    className="w-full border text-black border-gray-300 p-2 rounded"
                  />
                </div>
              ))}
            </div>

            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Verification Details
            </h2>

            <div className="grid grid-cols-2 gap-4 mb-4">
              {verificationFields.map((field) => (
                <div key={field.name}>
                  <label className="block mb-1">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={handleChange}
                    className="w-full border text-black border-gray-300 p-2 rounded"
                  />
                </div>
              ))}
            </div>
            <p className="text-sm  font-semibold text-[#606060] font-nunito mt-6 mb-2">
              Electricity Bill
            </p>
            <div className="w-full flex justify-between text-left px-4 py-3 bg-gray-100 text-sm border border-gray-300 rounded">
              <p className="text-black"> Electricity Bill Document</p>
              <button className="text-green text-sm font-semibold font-lato">
                View
              </button>
            </div>

            <p className="text-sm  font-semibold text-[#606060] font-nunito mt-6 mb-3">
              About (Bio)
            </p>
            <div className="grid grid-cols-1 gap-4 mb-4 text-black">
              <div className="">
                <label className="block mb-1"></label>
                <div className="flex justify-between items-center border border-[#D5D5D5] bg-[#f5f6fa] rounded-md">
                  <p className="text-black text-sm font-normal font-nunito leading-5 p-4">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
            </div>
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Address Details
            </h2>
            <h3 className="font-base text-black font-bold font-nunito underline md:mb-3">
              Residential Address
            </h3>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {addressFieldsResident.map((field) => (
                <div key={field.name}>
                  <label className="block mb-1">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={handleChange}
                    className="w-full border text-black border-gray-300 p-2 rounded"
                  />
                </div>
              ))}
            </div>

            <h3 className="font-base text-black font-bold font-nunito underline md:mb-3">
              Permanent Address
            </h3>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {addressFieldsPermanent.map((field) => (
                <div key={field.name}>
                  <label className="block mb-1">{field.label}</label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    onChange={handleChange}
                    className="w-full border text-black border-gray-300 p-2 rounded"
                  />
                </div>
              ))}
            </div>

            <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:mt-16">
              <ButtonGroup
                buttons={[
                  { label: "Delete", variant: "discard" },
                  {
                    label: "Reject",
                    variant: "delete",
                    disabled:
                      consumerData?.status === "verified" ||
                      consumerData?.status === "rejected",
                  },
                  {
                    label:
                      consumerData?.status === "verified"
                        ? "Verified"
                        : "Verify",
                    variant: "save",
                  },
                ]}
              />
            </div>
          </div>

          {/* Profile Picture Section */}
          <div className="col-span-1 flex flex-col items-center">
            <div className="mb-4 relative">
              <div className="w-32 h-32 rounded-full border-2 border-gray-300 bg-gray-300 flex items-center justify-center overflow-hidden">
                {consumerData.pic ? (
                  <img
                    alt="Profile"
                    className="w-full h-full object-cover"
                    src={consumerData.pic}
                  />
                ) : (
                  <span className="absolute inset-0 flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      className="w-12 h-12"
                    >
                      <path
                        d="M12 35.949C12.2574 38.5658 12.8391 40.3272 14.1538 41.6418C16.5119 44 20.3072 44 27.8978 44C35.4884 44 39.2838 44 41.6418 41.6418C44 39.2838 44 35.4884 44 27.8978C44 20.3072 44 16.5119 41.6418 14.1538C40.3272 12.8391 38.5658 12.2574 35.949 12"
                        stroke="black"
                        strokeWidth="2"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-col">
              <UploadButton
                label="Assessments"
                handleUpload={handleAssessments}
              />
              <UploadButton
                label="Bank Details"
                handleUpload={handleBankDetail}
              />
              <UploadButton
                label="Services Details"
                handleUpload={handleServiceDetails}
              />
              <UploadButton
                label="Booking Details"
                handleUpload={handleBookings}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ServiceProviderDetail;
