import React from "react";
import info from "../../../assets/info-circle.png";

const PricePopUp = ({ onClose }) => {
  const services = [
    { name: "Cleaning (per BHK)", charge: "₹7000" },
    { name: "Cooking Service", charge: "₹3000" },
    { name: "Patient Care", charge: "₹5000" },
    { name: "Vessel Cleaning", charge: "₹2000" },
    { name: "Household Help", charge: "₹2500" },
    { name: "Laundry Service", charge: "₹1500" },
    { name: "Gardening", charge: "₹3500" },
    { name: "Pest Control", charge: "₹4000" },
  ];

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded-xl  shadow-lg w-3/4 md:w-1/4">
        <div className="flex item-center justify-center md:mb-5">
          <img src={info} className="w-8 h-8" />
        </div>
        <h2 className="text-base text-center font-semibold text-black mb-4">
          Basic charges in your location
        </h2>
        <div className="space-y-2 border border-[#D9D9D9] rounded-lg p-4">
          {services.map((service, index) => (
            <div key={index} className="flex justify-between items-center">
              <label className="text-sm font-normal text-black">
                {service.name}
              </label>
              <span className="text-base font-manrope font-medium text-green">
                {service.charge}
              </span>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2  text-green rounded-full"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricePopUp;
