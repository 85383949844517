const refferalTable = [
  {
    id: "001",
    name: "John Doe",
    referralDate: "2023-12-01",
    status: "Redeemed",
  },
  {
    id: "002",
    name: "Jane Smith",
    referralDate: "2023-11-28",
    status: "Unused",
  },
  {
    id: "003",
    name: "Alice Johnson",
    referralDate: "2023-11-15",
    status: "Expired",
  },
];

export default refferalTable;
