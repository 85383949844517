import React from "react";
import Title from "../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";

const ViewBankDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};
  const additionalFields = [
    { label: "Account Number", name: "acc", value: "0987 7896 6789 4325" },
    { label: "IFSC Code", name: "ifsc", value: "CNHGT5678J" },
  ];

  const transactionDetails = [
    {
      id: "123456",
      date: "12 July, 2024",
      description: "Akshay Singh - Cooking Service",
      amount: "₹10,000",
    },
    {
      id: "123457",
      date: "15 July, 2024",
      description: "John Smith - Delivery Service",
      amount: "₹5,000",
    },
    {
      id: "123458",
      date: "18 July, 2024",
      description: "Diana Roy - Cleaning Service",
      amount: "₹7,500",
    },
  ];

  return (
    <div>
      <Title
        title={`${consumerData.username || "User"} - Bank Details`}
        goBack={() => navigate(-1)}
      />

      <div className="w-full bg-white p-8 rounded-2xl mb-8">
        <h2 className="text-xl font-bold text-black font-nunito mb-3">
          Linked Bank Details
        </h2>

        <div className="mb-8">
          <div className="grid grid-cols-2 w-4/5 gap-x-10 gap-y-3 mb-3">
            {additionalFields.map((field) => (
              <div key={field.name}>
                <label className="text-sm font-semibold text-[#606060] font-nunito">
                  {field.label}
                </label>
                <input
                  type="text"
                  name={field.name}
                  value={field.value}
                  placeholder="Enter here"
                  className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
                />
              </div>
            ))}
          </div>

          <h2 className="text-xl font-bold text-black font-nunito mb-3 mt-6">
            Transaction Details
          </h2>

          <div className="flex flex-col w-4/5 items-start space-y-4">
            {transactionDetails.map((transaction) => (
              <div key={transaction.id} className="w-full">
                <div className="flex w-full justify-between">
                  <div className="text-sm font-semibold text-[#606060] font-nunito">
                    Transaction ID #{transaction.id}
                  </div>
                  <div className="text-sm font-semibold text-[#606060] font-nunito">
                    {transaction.date}
                  </div>
                </div>

                <div className="flex w-full justify-between border-gray-300 rounded border px-4 py-3 bg-[#F5F6FA]">
                  <p className="block text-sm">{transaction.description}</p>
                  <p className="block text-green text-base font-bold">
                    {transaction.amount}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBankDetails;
