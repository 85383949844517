import serviceImg from "../../../assets/serviceImg.png";

const servicesData = [
  {
    id: 2,
    serviceName: "Cooking",
    subCategory: 2,
    subCategoryDetails: ["Vegetarian", "Non-Vegetarian"],
    serviceProviders: "william",
    consumers: "sophia",
    image: serviceImg,
  },
  {
    id: 3,
    serviceName: "Babysitting",
    subCategory: 3,
    subCategoryDetails: ["Infants", "Toddlers", "School-age"],
    serviceProviders: "emily",
    consumers: "daniel",
    image: serviceImg,
  },
  {
    id: 4,
    serviceName: "Pet Care",
    subCategory: 1,
    subCategoryDetails: ["Dogs", "Cats", "Birds"],
    serviceProviders: "oliver",
    consumers: "mia",
    image: serviceImg,
  },
  {
    id: 5,
    serviceName: "Patient Care",
    subCategory: 5,
    subCategoryDetails: ["Elderly", "Disabled", "Post-Surgery"],
    serviceProviders: "amelia",
    consumers: "logan",
    image: serviceImg,
  },
];

export default servicesData;
