import React, { useState } from "react";
import ButtonGroup from "../../Buttons/ButtonGroup";
import { useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import PricePopUp from "./PricePopUp";

const AddServiceDetails = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [serviceOptions] = useState([
    "Cooking Service",
    "Patient Care Service",
    "Vessel Cleaning",
    "Laundry Service",
    "Gardening",
  ]);

  // Mapping of subcategories for each service
  const subCategoryOptions = {
    "Cooking Service": [
      "Breakfast Preparation",
      "Lunch Preparation",
      "Dinner Preparation",
    ],
    "Patient Care Service": ["Elderly Care", "Child Care", "Post-Surgery Care"],
    "Vessel Cleaning": [
      "Kitchen Cleaning",
      "Dish Washing",
      "Utensil Polishing",
    ],
    "Laundry Service": ["Clothes Washing", "Ironing", "Dry Cleaning"],
    Gardening: ["Lawn Mowing", "Plant Watering", "Weeding"],
  };

  const handleServiceSelect = (e) => {
    const selectedService = e.target.value;
    if (selectedService && !services.includes(selectedService)) {
      setServices([...services, selectedService]);
      // Update subcategories based on selected service
      setSubCategories(subCategoryOptions[selectedService] || []);
    }
  };

  const handleRemoveService = (service) => {
    setServices(services.filter((s) => s !== service));
    // Clear subcategories if the removed service was selected
    if (services.length === 1) setSubCategories([]);
  };

  const handleSubCategorySelect = (e) => {
    const selectedSubCategory = e.target.value;
    if (selectedSubCategory) {
      alert(`Selected Sub-Category: ${selectedSubCategory}`);
    }
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <div className="flex flex-col items-center space-y-6 w-full">
      {/* Header */}
      <div className="flex items-center self-start space-x-4">
        <Title title="Add Service Details" goBack={() => navigate(-1)} />
      </div>

      <div className="w-full space-y-6 bg-white rounded-2xl p-8">
        <div className="flex flex-col items-start space-y-2">
          <label
            htmlFor="work"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Work Experience
          </label>
          <input
            type="text"
            placeholder="Enter Here"
            id="work"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>

        <div className="flex flex-col items-start space-y-2">
          <label
            htmlFor="additional"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Additional Details (Work Highlights)
          </label>
          <input
            type="text"
            placeholder="Enter Here"
            id="additional"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>
        <div className="grid grid-cols-2 w-4/5   ">
          <div>
            <label className="text-sm font-semibold text-[#606060] font-nunito">
              Owner Name (Previously worked for)
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter Here"
              className="w-[320px] border border-gray-300 p-2 rounded "
            />
          </div>
          <div>
            <label className="text-sm font-semibold text-[#606060] font-nunito">
              Owner Mobile Number (Previously worked for)
            </label>
            <input
              type="text"
              placeholder="Enter Here"
              name="gender"
              className="w-[320px] border border-gray-300 p-2 rounded"
            />
          </div>
        </div>
        <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
          Types of Services
        </h2>

        <div className="flex flex-col items-start space-y-4">
          <label
            htmlFor="service-dropdown"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Select Services
          </label>
          <select
            id="service-dropdown"
            onChange={handleServiceSelect}
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          >
            <option value="">Select</option>
            {serviceOptions.map((service) => (
              <option key={service} value={service}>
                {service}
              </option>
            ))}
          </select>

          <div className="w-3/4 mt-2">
            <input
              type="text"
              readOnly
              value={services.join(", ")}
              placeholder="Selected services will appear here"
              className="block w-full px-4 py-2 text-sm border border-gray-300 rounded bg-gray-100 focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>

          <div className="flex flex-wrap gap-2">
            {services.map((service) => (
              <div
                key={service}
                className="flex items-center px-3 py-1 bg-blue-200 rounded-full text-sm"
              >
                {service}
                <button
                  onClick={() => handleRemoveService(service)}
                  className="ml-2 text-red-500"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>

        <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
          Select Sub-Categories
        </h2>

        <div className="flex flex-col items-start space-y-4">
          <label
            htmlFor="subcategory-dropdown"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Select Sub-Categories
          </label>
          <select
            id="subcategory-dropdown"
            onChange={handleSubCategorySelect}
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          >
            <option value="">Select</option>
            {subCategories.map((subCategory) => (
              <option key={subCategory} value={subCategory}>
                {subCategory}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center self-center ">
          <h2 className="text-xl font-bold text-black font-nunito md:mr-2 ">
            Service Charges per Month
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={openPopup}
          >
            <path
              d="M9.99967 1.04134C14.9413 1.04134 18.958 5.05801 18.958 9.99967C18.958 14.9413 14.9413 18.958 9.99967 18.958C5.05801 18.958 1.04134 14.9413 1.04134 9.99967C1.04134 5.05801 5.05801 1.04134 9.99967 1.04134ZM9.99967 17.708C14.2497 17.708 17.708 14.2497 17.708 9.99967C17.708 5.74967 14.2497 2.29134 9.99967 2.29134C5.74967 2.29134 2.29134 5.74967 2.29134 9.99967C2.29134 14.2497 5.74967 17.708 9.99967 17.708Z"
              fill="#08875D"
            />
            <path
              d="M10 8.54134C10.3417 8.54134 10.625 8.82467 10.625 9.16634V13.333C10.625 13.6747 10.3417 13.958 10 13.958C9.65833 13.958 9.375 13.6747 9.375 13.333V9.16634C9.375 8.82467 9.65833 8.54134 10 8.54134Z"
              fill="#08875D"
            />
            <path
              d="M9.99967 5.83359C10.108 5.83359 10.2163 5.85859 10.3163 5.90026C10.4163 5.94193 10.508 6.00026 10.5913 6.07526C10.6663 6.15859 10.7247 6.24193 10.7663 6.35026C10.808 6.45026 10.833 6.55859 10.833 6.66693C10.833 6.77526 10.808 6.88359 10.7663 6.98359C10.7247 7.08359 10.6663 7.17526 10.5913 7.25859C10.508 7.33359 10.4163 7.39193 10.3163 7.43359C10.1163 7.51693 9.88301 7.51693 9.68301 7.43359C9.58301 7.39193 9.49134 7.33359 9.40801 7.25859C9.33301 7.17526 9.27468 7.08359 9.23301 6.98359C9.19134 6.88359 9.16634 6.77526 9.16634 6.66693C9.16634 6.55859 9.19134 6.45026 9.23301 6.35026C9.27468 6.24193 9.33301 6.15859 9.40801 6.07526C9.49134 6.00026 9.58301 5.94193 9.68301 5.90026C9.78301 5.85859 9.89134 5.83359 9.99967 5.83359Z"
              fill="#08875D"
            />
          </svg>
        </div>
        {isPopupOpen && <PricePopUp onClose={closePopup} />}
        <div className="flex flex-col items-start ">
          <label
            htmlFor="work"
            className="text-sm font-semibold text-[#606060] font-nunito"
          ></label>
          <input
            type="text"
            placeholder="Cooking Service (per person)"
            id="work"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>
        <div className="flex flex-col items-start ">
          <label
            htmlFor="work"
            className="text-sm font-semibold text-[#606060] font-nunito"
          ></label>
          <input
            type="text"
            placeholder="Patient Care Service (per person)"
            id="work"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>
        <div className="flex flex-col items-start  ">
          <label
            htmlFor="work"
            className="text-sm font-semibold text-[#606060] font-nunito"
          ></label>
          <input
            type="text"
            placeholder="Vessel Cleaning (per person)"
            id="work"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>

        <div className="flex gap-6 font-lato md:text-sm font-normal capitalize lg:mt-16">
          <ButtonGroup buttons={[{ label: "Save", variant: "save" }]} />
        </div>
      </div>
    </div>
  );
};

export default AddServiceDetails;
