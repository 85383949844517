import React from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import { totalTickets } from "../../data/helpsupport/totalTickets";
import TotalStat from "../MainDashboard/TotalStat/TotalStat";
import userData from "../../data/helpsupport/ticketTable";
import GenericTable from "../../GenericTable/GenericTable";

const HelpSuppportPage = () => {
  const navigate = useNavigate();
  const columns = [
    { header: "S.No.", key: "SNo" },
    { header: "Query Title", key: "name" },
    { header: "Date", key: "Date" },
    { header: "Status.", key: "status" },
  ];

  return (
    <div>
      <div className="flex flex-row gap-8">
        <Title title="Help & Support" goBack={() => navigate(-1)} />

        <div className="rounded-lg bg-black text-white font-lato item-center ">
          <button className="px-10 text-sm py-[10px]  font-bold text-white rounded-lg">
            CONSUMER
          </button>
        </div>

        <div className="rounded-lg bg-white border border-black text-white font-lato item-center ">
          <button className="px-10 text-sm py-[10px] font-bold  text-black rounded-lg">
            SERVICE PROVIDER
          </button>
        </div>
      </div>
      <div className="mt-9">
        <TotalStat data={totalTickets} />
        <GenericTable
          title=""
          columns={columns}
          data={userData}
          redirectTo="/viewticket-detail"
          showFilters={true}
        />
      </div>
    </div>
  );
};

export default HelpSuppportPage;
