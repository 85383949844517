import profilePic from "../../assets/profile.png";

const serviceProviderData = [
  {
    id: 1,
    name: "John Doe",
    doj: "15/08/2023",
    dob: "12/10/1965",
    marital: "Single",
    phone: "+91-9876543210",
    email: "johndoe@example.com",
    lang: "English, Hindi",
    adhar: "1234 5678 1234",
    edu: "B.Ed (English)",
    pan: "ABCDE1234F",
    pic: profilePic,
    gender: "Male",
    status: "rejected",
    address: {
      title: "Home",
      houseNumber: "21A",
      addressLine1: "Sunrise Villas",
      addressLine2: "Lakeview Road, Sector 10",
      pinCode: "560001",
      landmark: "Near City Park",
    },
    addressPermanent: {
      title: "Office",
      houseNumber: "B-215",
      addressLine1: "Oakwood Residency",
      addressLine2: "Sector 5, Hilltop Road",
      pinCode: "700089",
      landmark: "Near Shopping Complex",
    },
  },
  {
    id: 2,
    name: "Emily Clark",
    doj: "01/09/2023",
    dob: "12/10/1965",
    marital: "Single",
    edu: "B.Ed (English)",
    lang: "English, Hindi",
    phone: "+91-9876512345",
    email: "emilyclark@example.com",
    adhar: "5678 1234 5678",
    pan: "WXYZ9876P",
    pic: profilePic,
    gender: "Female",
    status: "pending",
    address: {
      title: "Office",
      houseNumber: "42C",
      addressLine1: "Tech Hub Building",
      addressLine2: "Sector 22, Business Lane",
      pinCode: "400050",
      landmark: "Opposite Metro Station",
    },
    addressPermanent: {
      title: "Office",
      houseNumber: "B-215",
      addressLine1: "Oakwood Residency",
      addressLine2: "Sector 5, Hilltop Road",
      pinCode: "700089",
      landmark: "Near Shopping Complex",
    },
  },
  {
    id: 3,
    name: "Michael Smith",
    doj: "12/12/2023",
    dob: "12/10/1965",
    marital: "Single",
    edu: "B.Ed (English)",
    phone: "+91-8765432109",
    email: "michaelsmith@example.com",
    adhar: "9876 5432 1098",
    pan: "LMNOP5432G",
    pic: profilePic,
    gender: "Male",
    status: "verified",
    lang: "English, Hindi",
    address: {
      title: "Home",
      houseNumber: "33B",
      addressLine1: "Oakwood Residency",
      addressLine2: "Sector 5, Hilltop Road",
      pinCode: "700089",
      landmark: "Near Shopping Complex",
    },
    addressPermanent: {
      title: "Office",
      houseNumber: "B-215",
      addressLine1: "Oakwood Residency",
      addressLine2: "Sector 5, Hilltop Road",
      pinCode: "700089",
      landmark: "Near Shopping Complex",
    },
  },
  {
    id: 4,
    name: "Alice Brown",
    doj: "20/10/2024",
    dob: "12/10/1965",
    marital: "Single",
    lang: "English, Hindi",
    edu: "B.Ed (English)",
    phone: "+91-9999999999",
    email: "akshaysingh@gmail.com",
    adhar: "1234 5678 9876",
    pan: "ABCDEF000A",
    pic: profilePic,
    gender: "Female",
    status: "verified",
    address: {
      title: "Home",
      houseNumber: "12B",
      addressLine1: "Greenwood Apartments",
      addressLine2: "Sector 45, Central Avenue",
      pinCode: "110011",
      landmark: "Near City Mall",
    },
    addressPermanent: {
      title: "Office",
      houseNumber: "B-215",
      addressLine1: "Oakwood Residency",
      addressLine2: "Sector 5, Hilltop Road",
      pinCode: "700089",
      landmark: "Near Shopping Complex",
    },
  },
];

export default serviceProviderData;
