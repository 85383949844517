import React, { useState } from "react";
import Title from "../../../Title/Title";
import { useLocation, useNavigate } from "react-router-dom";

const ViewAttendance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { consumerData } = location.state || {};

  const [currentDate, setCurrentDate] = useState(new Date());
  const [attendanceData] = useState([
    {
      date: "2024-09-13",
      status: "leave",
      reason: "Attending a family event.",
      approval: "Approved",
    },
    {
      date: "2024-09-15",
      status: "leave",
      reason: "Medical check-up appointment.",
      approval: "Approve Pending",
    },
  ]);

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const handleMonthChange = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + direction);
    setCurrentDate(newDate);
  };

  const generateCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const days = daysInMonth(year, month);
    const dates = [];

    for (let i = 1; i <= days; i++) {
      const dateStr = `${year}-${String(month + 1).padStart(2, "0")}-${String(
        i
      ).padStart(2, "0")}`;
      const record = attendanceData.find((item) => item.date === dateStr);
      dates.push({ day: i, status: record?.status || "present" });
    }

    return dates;
  };

  const calendarDays = generateCalendarDays();

  // Status color mapping
  const statusColors = {
    present: "bg-green-100 text-green-600",
    absent: "bg-red-100 text-red-600",
    leave: "bg-yellow-100 text-yellow-600",
  };

  return (
    <div className="max-w-sm ">
      <Title
        title={`${consumerData.consumername || "User"}- Attendance`}
        goBack={() => navigate(-1)}
      />
      <div className="bg-white shadow-lg rounded-lg md:mt-6 p-4">
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => handleMonthChange(-1)}
            className="text-gray-600 hover:text-gray-800"
          >
            &lt; Prev
          </button>
          <h2 className="text-lg font-semibold text-gray-800">
            {currentDate.toLocaleString("default", { month: "long" })}{" "}
            {currentDate.getFullYear()}
          </h2>
          <button
            onClick={() => handleMonthChange(1)}
            className="text-gray-600 hover:text-gray-800"
          >
            Next &gt;
          </button>
        </div>

        {/* Calendar Grid */}
        <div className="grid grid-cols-7 gap-2 mb-4">
          {calendarDays.map(({ day, status }, index) => {
            const isToday =
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                day
              ).toDateString() === new Date().toDateString();

            return (
              <div
                key={index}
                className={`w-10 h-10 flex items-center justify-center rounded-full ${
                  isToday
                    ? "bg-blue-100 text-blue-600 border-2 border-blue-400"
                    : statusColors[status] || "bg-gray-100 text-gray-600"
                }`}
              >
                {day}
              </div>
            );
          })}
        </div>

        {/* Leave Details Section */}
        <div>
          <h3 className="text-lg font-medium mb-2">Leave Reasons</h3>
          {attendanceData.map((item, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg p-4 mb-4"
            >
              <p className="text-sm text-gray-600">{item.date}</p>
              <p className="text-sm mt-1">{item.reason}</p>
              <p
                className={`text-sm mt-2 font-medium ${
                  item.approval === "Approved"
                    ? "text-green-600"
                    : "text-yellow-600"
                }`}
              >
                {item.approval}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewAttendance;
