import React from "react";
import { totalData } from "../../../data/totalStatData";
const TotalStat = ({ data }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">
      {data.map((item, index) => (
        <div
          key={index}
          className="flex flex-col justify-center bg-white shadow-md rounded-lg p-4 text-left"
        >
          <div className="flex justify-between">
            <h3 className="text-base text-[#202224] font-semibold opacity-70">
              {item.title}
            </h3>
            <img
              src={item.icon}
              alt={`${item.title} icon`}
              className="w-10 h-10"
            />
          </div>
          <p className="text-2xl mt-5 text-[#202224] font-bold">{item.count}</p>
        </div>
      ))}
    </div>
  );
};

export default TotalStat;
