const serviceproviderbookings = [
  {
    id: "A123456789012ABC1ABCDE",
    consumername: "john",
    name: "Cooking Service",
    serviceProvider: "Alisha Singh",
    timeSlot: "10:00 AM - 12:00 PM (Morning)",
    address: "X-25, Building name, Society, City, State, 201010",
    date: "10 September, 2023 - 10 October, 2023",
    status: "Completed",
    category: "Consumer",
    paymentMethod: "Google Pay + Wallet Money",
    orderplaced: "11-Sep-2024 12:00 PM",
    paidVia: "Google Pay (Bank name XX1234)",
    coupon: "XYZ Coupon Applied",
    tip: "₹20",
  },
  {
    id: "B234567890123DEF2BCDEF",
    consumername: "sam",
    name: "Patient Care Service",
    serviceProvider: "Neha Verma",
    timeSlot: "9:00 AM - 11:00 AM (Morning)",
    address: "A-100, Green Valley, City, State, 201011",
    date: "10 September, 2023 - 10 October, 2023",
    status: "In Progress",
    category: "Service Provider",
    paymentMethod: "Cash",
    orderplaced: "12-Sep-2024 10:00 AM",
    paidVia: "Cash",
    coupon: "None",
    tip: "₹50",
  },
  {
    id: "C345678901234GHI3CDEFG",
    consumername: "john",
    name: "Pet Care Service",
    serviceProvider: "Ravi Kumar",
    timeSlot: "8:00 AM - 10:00 AM (Morning)",
    address: "B-150, Pet Paradise, City, State, 201012",
    date: "10 September, 2023 - 10 October, 2023",
    status: "Completed",
    category: "Consumer",
    paymentMethod: "Debit Card",
    orderplaced: "13-Sep-2024 09:30 AM",
    paidVia: "Debit Card (Bank name XY1234)",
    coupon: "Pet Care Discount Applied",
    tip: "₹30",
  },
  {
    id: "D456789012345JKL4DEFGH",
    consumername: "john",
    name: "Vessel Cleaning",
    serviceProvider: "Suresh Yadav",
    timeSlot: "2:00 PM - 4:00 PM (Afternoon)",
    address: "C-200, River Side, City, State, 201013",
    date: "10 September, 2023 - 10 October, 2023",
    status: "Replaced",
    category: "Service Provider",
    paymentMethod: "UPI",
    orderplaced: "14-Sep-2024 01:00 PM",
    paidVia: "UPI (Bank name AB1234)",
    coupon: "Vessel Cleaning Offer",
    tip: "₹40",
  },
  {
    id: "E567890123456MNO5ABCDE",
    consumername: "alice",
    name: "Full Time",
    serviceProvider: "Priya Singh",
    timeSlot: "10:00 AM - 6:00 PM (Full Day)",
    address: "D-50, Work Hub, City, State, 201014",
    date: "10 September, 2023 - 10 October, 2023",
    status: "Request Rejected",
    category: "Consumer",
    paymentMethod: "Credit Card",
    orderplaced: "15-Sep-2024 11:00 AM",
    paidVia: "Credit Card (Bank name XY7890)",
    coupon: "No Coupon",
    tip: "₹20",
  },
  {
    id: "F678901234567PQR6FGHIJ",
    consumername: "john",
    name: "Babysitting",
    serviceProvider: "Amit Sharma",
    timeSlot: "12:00 PM - 2:00 PM (Afternoon)",
    address: "E-80, Family Care, City, State, 201015",
    date: "10 September, 2023 - 10 October, 2023",
    status: "Request Pending",
    category: "Service Provider",
    paymentMethod: "Google Pay",
    orderplaced: "16-Sep-2024 02:30 PM",
    paidVia: "Google Pay (Bank name ZY1234)",
    coupon: "None",
    tip: "₹25",
  },
];

export default serviceproviderbookings;
