import React, { useState } from "react";

const UploadProfilePic = ({ buttonText = "Add Profile Pic." }) => {
  const [formData, setFormData] = useState({
    profilePic: null,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ profilePic: file });
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className="mb-8 relative">
      <div className="w-32 h-32 rounded-full border-2 border-gray-300 bg-gray-300 flex items-center justify-center overflow-hidden">
        {formData.profilePic ? (
          <img
            alt="Profile"
            className="w-full h-full object-cover"
            src={URL.createObjectURL(formData.profilePic)}
          />
        ) : (
          <span className="absolute flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              className="w-12 h-12"
            >
              <path
                d="M12 35.949C12.2574 38.5658 12.8391 40.3272 14.1538 41.6418C16.5119 44 20.3072 44 27.8978 44C35.4884 44 39.2838 44 41.6418 41.6418C44 39.2838 44 35.4884 44 27.8978C44 20.3072 44 16.5119 41.6418 14.1538C40.3272 12.8391 38.5658 12.2574 35.949 12"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M4 20C4 12.4575 4 8.6863 6.34314 6.34314C8.6863 4 12.4575 4 20 4C27.5424 4 31.3138 4 33.6568 6.34314C36 8.6863 36 12.4575 36 20C36 27.5424 36 31.3138 33.6568 33.6568C31.3138 36 27.5424 36 20 36C12.4575 36 8.6863 36 6.34314 33.6568C4 31.3138 4 27.5424 4 20Z"
                stroke="black"
                strokeWidth="2"
              />
              <path
                d="M4 22.2371C5.23804 22.0797 6.48968 22.0021 7.74342 22.0047C13.0473 21.9067 18.2213 23.3527 22.3422 26.0849C26.164 28.6189 28.8494 32.1061 30 36.0001"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.9995 14H26.0175"
                stroke="black"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        )}
      </div>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={handleFileChange}
      />
      <button
        type="button"
        onClick={triggerFileInput}
        className="w-full text-black p-2 rounded border border-black md:mt-8"
      >
        {buttonText}
      </button>
    </div>
  );
};

export default UploadProfilePic;
