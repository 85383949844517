import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import ButtonGroup from "../../Buttons/ButtonGroup";

const ReviewCoupon = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const couponData = location.state;

  const formFields = couponData
    ? [
        {
          name: "name",
          label: "Coupon Name",
          value: couponData.name,
        },

        {
          name: "couponDesc",
          label: "Coupon Description",
          value: couponData.desc,
        },
        {
          name: "couponCode",
          label: "Coupon Code",
          value: couponData.couponCode,
        },

        {
          name: "percentoff",
          label: "% Off",
          value: couponData.percentoff || "",
        },

        {
          name: "expDate",
          label: "Expires On (Valid till)",
          value: couponData.expDate,
        },
      ]
    : [];

  return (
    <div>
      <div>
        <Title
          title={couponData ? couponData.name : "Coupon Review"}
          goBack={() => navigate(-1)}
        />
      </div>
      <div>
        <div className="bg-white p-8 rounded-2xl mb-6">
          {couponData ? (
            <div className="grid grid-cols-1 gap-6 w-3/4">
              {formFields.map((field) => (
                <div key={field.name} className="grid grid-cols-1">
                  <label className="block mb-1 text-[#606060]">
                    {field.label}
                  </label>
                  <input
                    type="text"
                    name={field.name}
                    value={field.value}
                    placeholder={field.placeholder}
                    readOnly={
                      field.name === "refundamt" ||
                      field.name === "serviceprovider"
                    }
                    className={`w-full border text-black border-gray-300 p-2 rounded ${
                      field.name === "refundamt" ||
                      field.name === "serviceprovider"
                        ? "bg-gray-100 cursor-not-allowed"
                        : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>No data available</p>
          )}
          {couponData && (
            <div className="mt-14">
              <ButtonGroup
                buttons={[
                  {
                    label: "Discard",
                    variant: "discard",
                  },
                  {
                    label: "Edit",
                    variant: "delete",
                  },
                  {
                    label: "Save",
                    variant: "save",
                    // onClick: handleConfirmClick,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewCoupon;
