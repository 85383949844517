import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadButton from "../../Buttons/UploadButton";
import UploadPrfilePic from "../../Buttons/UploadProfilePic";

function AddNewConsumer() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    dateOfJoining: "",
    mobileNo: "",
    profilePic: null,
    document: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
  };
  const handleUpload = () => {
    navigate("/upload-doc?mode=upload");
  };
  return (
    <div className="mx-auto">
      <div className="flex items-center self-start space-x-4 md:mb-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="22"
          viewBox="0 0 13 22"
          fill="none"
          onClick={() => navigate(-1)} // Go back to the previous page
          className="cursor-pointer"
        >
          <path d="M12 1L2 11L12 21" stroke="#202224" strokeWidth="2" />
        </svg>
        <h2 className="text-3xl font-bold font-nunito text-[#202224] ">
          Add New Consumer
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 text-[#606060] md:mb-8 md:text-sm font-semibold font-nunito bg-white p-8 rounded-2xl"
      >
        <div className="grid grid-cols-3 gap-8">
          {/* Form Section */}
          <div className="col-span-2">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">User ID</label>
                <input
                  type="text"
                  name="id"
                  value={formData.id}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Date of Joining</label>
                <input
                  type="date"
                  name="dateOfJoining"
                  value={formData.dateOfJoining}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Personal Details
            </h2>

            {/* Full Name and Gender Fields */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Gender</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Mobile no.</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Email</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Aadhaar Number</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">PAN Number</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Address Details
            </h2>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Title</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>

              <div>
                <label className="block mb-1">House No./Flat No.</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Address line 1</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>

              <div>
                <label className="block mb-1">Address line 2</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block mb-1">Pin Code</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
              <div>
                <label className="block mb-1">Landmark</label>
                <input
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:mt-16">
              <ButtonGroup
                buttons={[
                  {
                    label: "Discard",
                    variant: "discard",
                  },
                  { label: "Delete", variant: "delete" },
                  { label: "Save", variant: "save" },
                ]}
              />
            </div>
          </div>
          {/* Profile Picture Section */}
          <div className="col-span-1 flex flex-col items-center">
            <UploadPrfilePic />
            <UploadButton label="Upload Document" handleUpload={handleUpload} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewConsumer;
