import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";
import UploadButton from "../../Buttons/UploadButton";
import UploadPrfilePic from "../../Buttons/UploadProfilePic";
import Title from "../../Title/Title";

function AddNewService() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    dateOfJoining: "",
    mobileNo: "",
    profilePic: null,
    document: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted", formData);
  };
  const handleUpload = () => {
    navigate("/upload-doc?mode=upload");
  };
  return (
    <div className="mx-auto">
      <div className="flex items-center self-start space-x-4 md:mb-8">
        <Title title=" Add New Service" goBack={() => navigate(-1)} />
      </div>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 text-[#606060] md:mb-8 md:text-sm font-semibold font-nunito bg-white py-8 px-5 rounded-2xl"
      >
        <div className="grid grid-cols-3 gap-8">
          {/* Form Section */}
          <div className="col-span-2">
            <div className="grid grid-cols-1 gap-4 mb-4">
              <div>
                <label className="block mb-1">Service Category Name</label>
                <input
                  type="text"
                  name="category"
                  //   value={formData.id}
                  placeholder="Enter here"
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 mb-6">
              <div>
                <label className="block mb-1">Sub-Categories</label>
                <input
                  type="text"
                  name="sub"
                  //   value={formData.id}
                  placeholder="Enter here"
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mb-6">
              <div className="border border-dashed w-2/4 rounded-lg border-green p-3">
                <p className="text-center text-green font-lato text-sm font-normal">
                  + Add New Sub-Category
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mb-4">
              <div>
                <label className="block mb-1">
                  Total Service Providers offering cooking services
                </label>
                <input
                  type="text"
                  name="totalrate"
                  //   value={formData.id}
                  placeholder="Enter here"
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mb-4">
              <div>
                <label className="block mb-1">
                  Total Consumers using cooking services
                </label>
                <input
                  type="text"
                  name="totalrate"
                  //   value={formData.id}
                  placeholder="Enter here"
                  onChange={handleChange}
                  className="w-full border border-gray-300 p-2 rounded"
                />
              </div>
            </div>
            {/* 
            <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
              Address Details
            </h2> */}

            <div className="flex gap-6 font-lato md:text-sm font-normal capitalize md:mt-16">
              <ButtonGroup
                buttons={[
                  {
                    label: "Discard",
                    variant: "discard",
                  },
                  { label: "Delete", variant: "delete" },
                  { label: "Save", variant: "save" },
                ]}
              />
            </div>
          </div>
          {/* Profile Picture Section */}
          <div className="col-span-1 flex flex-col items-center">
            <UploadPrfilePic buttonText=" Add Picture" />
            <p className="text-xs text-green font-lato font-normal">
              Note - Image should not be larger than 1MB (250*450)
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewService;
