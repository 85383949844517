import React from "react";
import Title from "../../Title/Title";
import GenericTable from "../../GenericTable/GenericTable";

import userData from "../../data/consumerTable";
import { Navigate, useParams } from "react-router-dom";
const ServiceProvingService = () => {
  const { serviceName } = useParams();

  const columns = [
    { header: "User ID", key: "id" },
    { header: "Consumer Name", key: "name" },
    { header: "Gender", key: "gender" },
    { header: "Mobile No.", key: "phone" },
    { header: "Status", key: "status" },
  ];
  return (
    <div>
      <Title
        title={`Service Providers offering ${serviceName} services`}
        goBack={() => Navigate(-1)}
      />

      <GenericTable
        title=""
        columns={columns}
        data={userData}
        redirectTo="/review"
        showFilters={true}
        reviewText="View Details"
      />
    </div>
  );
};

export default ServiceProvingService;
