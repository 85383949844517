export const referralData = [
  {
    id: "008",
    date: "16/01/2025",
    referredThrough: "Shared Link",
    couponStatus: "Successful",
  },
  {
    id: "008",
    date: "16/01/2025",
    referredThrough: "Shared Code",
    couponStatus: "Successful",
  },
];

export const couponData = [
  {
    title: "Referral Reward",
    description: "Get 10% off on your first transaction.",
    code: "GET10",
    status: "Expired",
    date: "12/06/2024",
    color: "bg-red-100 text-[#d20000] border-[#d20000]",
  },
  {
    title: "Coupon Title",
    description: "Coupon Description",
    code: "COUPON10",
    status: "Expires",
    date: "12/01/2025",
    color: "bg-yellow-100 border-yellow-500 text-yellow-500",
  },
  {
    title: "Coupon Title",
    description: "Coupon Description",
    code: "COUPON10",
    status: "Redeemed",
    date: "12/01/2025",
    color: "bg-green-100 text-green-500",
  },
];
