import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";
import GenericTable from "../../GenericTable/GenericTable";
import userData from "../../data/serviceprovider/bookings";

const ViewBookings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};
  const columns = [
    { key: "name", header: "Service Name" },
    { key: "date", header: "Date" },
    { key: "status", header: "Status" },
  ];
  return (
    <div>
      <Title
        title={`${consumerData.username || "User"} - Bookings`}
        goBack={() => navigate(-1)}
      />
      <GenericTable
        title=""
        columns={columns}
        data={userData}
        reviewText="View Details"
        redirectTo="/view-booking-details"
      />
    </div>
  );
};

export default ViewBookings;
