import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";

const ViewServiceDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};

  // Static data
  const services = [
    "Cooking Service",
    "Patient Care Service",
    "Vessel Cleaning",
    "Laundry Service",
    "Gardening",
  ];

  // Mapping of subcategories for each service
  const subCategoryOptions = {
    "Cooking Service": ["Breakfast Preparation"],
    "Patient Care Service": ["Elderly Care"],
    "Vessel Cleaning": ["Kitchen Cleaning"],
    "Laundry Service": ["Clothes Washing"],
    Gardening: ["Lawn Mowing"],
  };

  // Mapping of charges for each service
  const serviceCharges = {
    "Cooking Service": 10000,
    "Patient Care Service": 15000,
    "Vessel Cleaning": 5000,
    "Laundry Service": 4000,
    Gardening: 3000,
  };

  // Time slots for Morning and Afternoon
  const timeSlots = {
    morning: ["06:00 AM - 08:00 AM", "08:00 AM - 10:00 AM"],
    afternoon: ["01:00 PM - 03:00 PM", "03:00 PM - 05:00 PM"],
    evening: ["01:00 PM - 03:00 PM"],
  };

  return (
    <div className="flex flex-col items-center space-y-0 w-full mb-8">
      {/* Header */}
      <div className="flex items-center self-start space-x-4">
        <Title
          title={`${consumerData.username || "User"} - Services Details`}
          goBack={() => navigate(-1)}
        />
      </div>

      <div className="w-full space-y-3 bg-white rounded-2xl p-8">
        <div className="flex flex-col items-start space-y-2">
          <label
            htmlFor="work"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Work Experience
          </label>
          <input
            type="text"
            value="02"
            id="work"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>

        <div className="flex flex-col items-start space-y-2">
          <label
            htmlFor="additional"
            className="text-sm font-semibold text-[#606060] font-nunito"
          >
            Additional Details (Work Highlights)
          </label>

          <input
            value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            id="additional"
            className="block w-3/4 text-left px-4 py-2 text-sm border border-gray-300 rounded cursor-pointer focus:outline-none focus:ring focus:ring-blue-200"
          />
        </div>

        <div className="grid grid-cols-2 w-4/5">
          <div>
            <label className="text-sm font-semibold text-[#606060] font-nunito">
              Owner Name (Previously worked for)
            </label>
            <input
              type="text"
              value="Kunal Gupta"
              className="w-[320px] border border-gray-300 p-2 rounded"
            />
          </div>
          <div>
            <label className="text-sm font-semibold text-[#606060] font-nunito">
              Owner Mobile Number (Previously worked for)
            </label>
            <input
              type="text"
              value="+91-9156565678"
              name="gender"
              className="w-[320px] border border-gray-300 p-2 rounded"
            />
          </div>
        </div>

        <h2 className="text-xl font-bold text-black font-nunito mt-6 mb-3">
          Selected Service Categories to Offer
        </h2>

        <div className="flex flex-col space-y-4">
          <p className="text-sm text-end w-3/4 font-semibold text-[#606060] font-nunito">
            Charges per month
          </p>

          <div className="w-3/4 mt-2 flex flex-col gap-y-3">
            {services.map((service, index) => (
              <div
                key={index}
                className="flex justify-between w-full px-4 py-2 text-sm border border-gray-300 rounded bg-gray-100 focus:outline-none"
              >
                <p className="text-black text-sm text-normal">
                  {service} (per person)
                </p>
                <p className="font-lato font-bold font-base text-green">
                  ₹{serviceCharges[service] || 0}
                </p>
              </div>
            ))}
          </div>
        </div>

        <h2 className="text-xl font-bold text-black font-nunito mb-3">
          Selected Sub - Categories
        </h2>

        <div className="flex flex-col w-3/4 space-y-3 items-start">
          {services.map(
            (service, index) =>
              subCategoryOptions[service] &&
              subCategoryOptions[service].map((subCategory, subIndex) => (
                <div
                  key={`${index}-${subIndex}`}
                  className="flex justify-between w-full px-4 py-2 text-sm border border-gray-300 rounded bg-gray-100 focus:outline-none"
                >
                  <p className="text-black text-sm text-normal">
                    {subCategory}
                  </p>
                </div>
              ))
          )}
        </div>

        <div>
          <h2 className="text-xl font-bold text-black font-nunito mb-3 md:mt-6">
            Time Slots
          </h2>

          <p className="text-sm font-nunito font-semibold mb-2">Morning</p>

          <div className="flex flex-col w-3/4 space-y-3 items-start">
            {timeSlots.morning.map((slot, index) => (
              <div
                key={index}
                className="flex justify-between w-full px-4 py-2 text-sm border border-gray-300 rounded bg-gray-100 focus:outline-none"
              >
                <p className="text-black text-sm text-normal">{slot}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <p className="text-sm font-nunito font-semibold ">Afternoon</p>

          <div className="flex flex-col space-y-3 w-3/4 items-start">
            {timeSlots.afternoon.map((slot, index) => (
              <div
                key={index}
                className="flex justify-between mt-3 w-full px-4 py-2 text-sm border border-gray-300 rounded bg-gray-100 focus:outline-none"
              >
                <p className="text-black text-sm text-normal">{slot}</p>
              </div>
            ))}
          </div>
        </div>

        <div>
          <p className="text-sm font-nunito font-semibold ">Evening</p>

          <div className="flex flex-col space-y-3 w-3/4 items-start">
            {timeSlots.evening.map((slot, index) => (
              <div
                key={index}
                className="flex justify-between mt-3 w-full px-4 py-2 text-sm border border-gray-300 rounded bg-gray-100 focus:outline-none"
              >
                <p className="text-black text-sm text-normal">{slot}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewServiceDetails;
