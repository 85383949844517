import React, { useState } from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import dummyImage from "../../../assets/bg.jpg";
import edit from "../../../assets/edit.png";
import del from "../../../assets/del.png";
import ButtonGroup from "../../Buttons/ButtonGroup";

const HomeBanner = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([
    { id: 1, image: dummyImage },
    { id: 2, image: dummyImage },
    { id: 3, image: dummyImage },
  ]);

  const handleImageUpload = (e, id) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBanners((prev) =>
          prev.map((banner) =>
            banner.id === id ? { ...banner, image: reader.result } : banner
          )
        );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (id) => {
    setBanners((prev) =>
      prev.map((banner) =>
        banner.id === id ? { ...banner, image: dummyImage } : banner
      )
    );
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Title title="Homescreen Banners" goBack={() => navigate(-1)} />
        </div>
        <button className="bg-green-500 text-white py-3 px-10 rounded-lg">
          + Add New Banner
        </button>
      </div>

      <div>
        <p className="text-right mt-6 font-lato text-xs font-normal">
          Note - Image should not be larger than 1MB (250*450)
        </p>
      </div>

      <div className="mt-10 bg-white p-8 rounded-2xl mb-8">
        {banners.map((banner) => (
          <div key={banner.id} className="mb-8">
            <p className="text-left font-bold text-xl font-nunito mb-4">
              Banner {banner.id}
            </p>
            <div className="flex items-baseline">
              {/* Image Container */}
              <div className="w-1/2  border border-dashed p-4 border-green rounded-lg flex items-center justify-center bg-gray-200">
                {banner.image ? (
                  <img
                    src={banner.image}
                    alt={`Banner ${banner.id}`}
                    className="w-full h-32 object-cover rounded-lg"
                  />
                ) : (
                  <span className="text-gray-500">No Image</span>
                )}
              </div>

              <div className="ml-4 flex items-baseline">
                <input
                  type="file"
                  id={`upload-${banner.id}`}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, banner.id)}
                />
                <button
                  className=" px-2 py-2"
                  onClick={() =>
                    document.getElementById(`upload-${banner.id}`).click()
                  }
                >
                  <img src={edit} alt="Edit" className="w-5 h-5" />
                </button>
                <button
                  className=" px-2 py-2"
                  onClick={() => handleRemoveImage(banner.id)}
                >
                  <img src={del} alt="Delete" className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        <button className="bg-green-500 text-white py-3 px-10 rounded-lg mt-6">
          Save Changes
        </button>
        <ButtonGroup buttons={[{ label: "Save Changes", variant: "save" }]} />
      </div>
    </div>
  );
};

export default HomeBanner;
