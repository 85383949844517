import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../Title/Title";

const ViewTicketDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};
  const additionalFields = [
    { label: "Title of Query", name: "name", value: consumerData.name },
    { label: "Ticket ID", name: "ticketid", value: consumerData.ticketid },
    { label: "Date", name: "date", value: consumerData.Date },
    { label: "User Full Name", name: "username", value: consumerData.username },
    {
      label: "User Mobile No.",
      name: "phone",
      value: consumerData.phone,
    },
    { label: "User E-mail", name: "email", value: consumerData.email },
  ];
  const { state: ticket } = useLocation();

  if (!ticket) {
    return <div>No ticket details available.</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center self-center">
        <Title title={`${ticket.name}`} goBack={() => navigate(-1)} />

        <div className="flex gap-6 items-center">
          <button className="text-[#344054] border text-sm font-lato font-normal h-10 px-5 rounded-lg border-[#D0D5DD] flex items-center justify-center">
            Export
          </button>

          <span
            className={` ${consumerData?.statusColor} text-black font-bold font-nunito px-4 py-1 text-sm rounded-[13px] h-7 flex items-center justify-center`}
          >
            {consumerData?.status}
          </span>
        </div>
      </div>
      <div className="mt-9 bg-white rounded-2xl mb-8 p-8">
        <div className="grid grid-cols-2 w-4/5 gap-x-10 gap-y-3 mb-3">
          {additionalFields.map((field) => (
            <div key={field.name}>
              <label className="text-sm font-semibold text-[#606060] font-nunito">
                {field.label}
              </label>
              <input
                type="text"
                name={field.name}
                value={field.value}
                placeholder="Enter here"
                className="w-full border text-black border-gray-300 p-2 rounded bg-gray-100 cursor-not-allowed"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewTicketDetail;
