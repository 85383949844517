import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Title from "../Title/Title";
import UploadButton from "../Buttons/UploadButton";

const FinanceManagementPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const consumerData = location.state || {};
  const handleRefund = () => {
    navigate("/refund", {
      state: {
        consumername: consumerData.consumername,
        refundStatus: consumerData.refundStatus,
      },
    });
  };

  const handleReferal = () => {
    navigate("/refferal", {
      state: {
        consumername: consumerData.consumername,
        refundStatus: consumerData.refundStatus,
      },
    });
  };

  const handleCoupon = () => {
    navigate("/coupon-offer", {
      state: {
        consumername: consumerData.consumername,
        refundStatus: consumerData.refundStatus,
      },
    });
  };

  const handleTax = () => {
    navigate("/tax-management", {
      state: {
        consumername: consumerData.consumername,
        refundStatus: consumerData.refundStatus,
      },
    });
  };

  const handlePayment = () => {
    navigate("/service-provider-payment", {
      state: {
        consumername: consumerData.consumername,
        refundStatus: consumerData.refundStatus,
      },
    });
  };
  return (
    <div>
      <div>
        <Title title="Finance Management" goBack={() => navigate(-1)} />
      </div>

      <div className="container">
        <div className="grid grid-cols-2 gap-9">
          <UploadButton label="Refunds" handleUpload={handleRefund} />
          <UploadButton label="Referrals" handleUpload={handleReferal} />
        </div>

        <div className="grid grid-cols-2 gap-9">
          <UploadButton
            label="Coupons & Offers Management"
            handleUpload={handleCoupon}
          />
          <UploadButton label="Tax Management" handleUpload={handleTax} />
        </div>

        <div className="grid grid-cols-2 gap-9">
          <UploadButton
            label="Service Provider (Maid) Payments"
            handleUpload={handlePayment}
          />
          <UploadButton label="Police Verification" />
        </div>
      </div>
    </div>
  );
};

export default FinanceManagementPage;
