import React from "react";
import { useNavigate } from "react-router-dom";

const Title = ({ title, goBack, className }) => {
  const navigate = useNavigate();

  return (
    <div className={`flex md:space-x-4  items-center ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="22"
        viewBox="0 0 13 22"
        fill="none"
        onClick={goBack ? goBack : () => navigate(-1)}
        className="cursor-pointer"
      >
        <path d="M12 1L2 11L12 21" stroke="#202224" strokeWidth="2" />
      </svg>
      <h1 className="text-3xl font-bold font-nunito text-[#202224]">{title}</h1>
    </div>
  );
};

export default Title;
