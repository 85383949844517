import React, { useState } from "react";
import Title from "../../Title/Title";
import { useNavigate } from "react-router-dom";
import ButtonGroup from "../../Buttons/ButtonGroup";

const GenericTC = ({
  initialTitle = "Default Title",
  initialTerms = "Default terms and conditions.",
  onSave = (data) => console.log("Save button clicked", data),
  goBack,
  label = "Terms and Conditions",
}) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState(initialTitle);
  const [terms, setTerms] = useState(initialTerms);

  const handleEdit = () => {
    console.log("Edit button clicked");
  };

  const handleDelete = () => {
    setTerms("");
    console.log("Delete button clicked");
    alert("Content has been cleared.");
  };

  const handleSave = () => {
    console.log("Save button clicked", { title, terms });
    onSave({ title, terms });
    alert("Content has been saved.");
  };

  return (
    <div>
      <div>
        <Title title={title} goBack={goBack || (() => navigate(-1))} />
      </div>
      <div className="bg-white rounded-2xl mb-8 mt-8 p-8 h-screen">
        <div className="flex flex-col">
          {/* Dynamic Label */}
          <label className="text-[#606060] font-semibold mb-3 font-nunito text-sm">
            {label}
          </label>
          <textarea
            rows="6"
            className="p-4 border rounded-md"
            value={terms}
            onChange={(e) => setTerms(e.target.value)}
          ></textarea>
        </div>
        <div className="mt-16">
          <ButtonGroup
            buttons={[
              {
                label: "Delete",
                variant: "discard",
                onClick: handleDelete,
              },
              {
                label: "Edit",
                variant: "delete",
                onClick: handleEdit,
              },
              {
                label: "Save",
                variant: "save",
                onClick: handleSave,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default GenericTC;
